import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import Layout from './components/Layout/Layout';
import Dokumentation from './containers/Dokumentation/Dokumentation';
import Einstellungen from './containers/Einstellungen/Einstellungen';
import Logger from './containers/Logger/Logger';
import Login from './containers/Login/Login';
import Register from './containers/Register/Register';
import { PrivateRoute } from './components/privateRoute';
import ResetPassword from './containers/Login/ResetPassword';

import 'typeface-roboto';
import * as actions from './store/actions/index';


class App extends Component {
  state = {
    meldung: true,
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ meldung: false });
  };


  componentDidMount() {
    const u_id = localStorage.getItem('u_id');
    const u_name = localStorage.getItem('u_name');
    const u_token = localStorage.getItem('u_token');
    const u_rolle = localStorage.getItem('u_rolle');
    const user = {
      u_id: u_id,
      u_name: u_name,
      u_token: u_token,
      u_rolle: u_rolle
    }
    if (u_id !== null) {
      this.props.loginUserFromStorage(user);
    }

  }

  HomePage = (
    <Layout>
      <Dokumentation></Dokumentation>
    </Layout>
  );


  render() {

    return (
      <Layout>
        <Snackbar open={this.props.updateAvailable} autoHideDuration={60000} onClose={this.handleClose}>
          <MuiAlert elevation={6} variant="filled" onClose={this.handleClose} severity="infos">
            Update gefunden! Bitte starte die App neu
        </MuiAlert>
        </Snackbar>
        <Switch>
          <PrivateRoute exact path="/" loggedIn={this.props.loggedIn} component={Dokumentation} />
          <PrivateRoute exact path="/dokumentationen" loggedIn={this.props.loggedIn} component={Dokumentation} />
          <PrivateRoute exact path="/einstellungen" loggedIn={this.props.loggedIn} component={Einstellungen} />
          <PrivateRoute exact path="/logger" loggedIn={this.props.loggedIn} component={Logger} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/logout" component={Login} />
          <Route path="/reset/:token" component={ResetPassword} />
          <Redirect from="*" to="/" />
        </Switch>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    loggedIn: state.users.loggedIn,
    updateAvailable: state.serviceWorker.serviceWorkerUpdated,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    loginUserFromStorage: (loginData) => dispatch(actions.loginUserFromStorage(loginData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
