import React from 'react';
import axios from '../../../axios';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { green } from '@material-ui/core/colors';
import Dialog from '../../UI/Dialog/Dialog';
import { connect } from 'react-redux';

class OptionenUser extends React.Component {
    state = {
        allUsers: null,
        showDialog: false,
        lastDelete: false
    }

    componentDidMount() {
        axios.get('/user/')
            .then(response => {
                const alleuser = response.data.map(wer => {
                    return ({ id: wer.u_id, name: wer.u_name, eMail: wer.u_email, rolle: wer.u_rolle, aktiv: wer.u_aktiv, saved: false });
                });
                this.setState({
                    allUsers: alleuser,
                });
            });

    }
    usernameChangeHandler = (event, id) => {
        const { value } = event.target;
        const userIndex = this.state.allUsers.findIndex(p => {
            return p.id === id;
        });

        const users = [...this.state.allUsers];
        users[userIndex].name = value;
        this.setState({ allUsers: users });
    }
    eMailChangeHandler = (event, id) => {
        const { value } = event.target;
        const userIndex = this.state.allUsers.findIndex(p => {
            return p.id === id;
        });

        const users = [...this.state.allUsers];
        users[userIndex].eMail = value;
        this.setState({ allUsers: users });
    }
    rolleChangeHandler = (event, id) => {
        const { value } = event.target;
        const userIndex = this.state.allUsers.findIndex(p => {
            return p.id === id;
        });

        const users = [...this.state.allUsers];
        users[userIndex].rolle = value;
        this.setState({ allUsers: users });
    }
    aktivChangeHandler = (id) => {
        const userIndex = this.state.allUsers.findIndex(p => {
            return p.id === id;
        });
        const users = [...this.state.allUsers];
        users[userIndex].aktiv === 0 ? users[userIndex].aktiv = 1 : users[userIndex].aktiv = 0;
        this.setState({ allUsers: users });
    }

    deleteUser = (id) => {
        axios.delete('/user/' + id)
            .then(response => {
                const userIndex = this.state.allUsers.findIndex(p => {
                    return p.id === id;
                });
                let alleuser = [...this.state.allUsers];
                alleuser.splice(userIndex, 1);
                this.setState({ allUsers: alleuser });
                this.toggleDialog();
            })
            .catch(err => {
                this.setState({ lastDelete: true });
                this.toggleDialog();
            });
    }

    toggleDialog = () => this.setState({ showDialog: !this.state.showDialog })

    handleSubmit = (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        const userIndex = this.state.allUsers.findIndex(p => {
            return p.id === id;
        });

        const users = [...this.state.allUsers];
        users[userIndex].saved = true;

        const { name, eMail, rolle, aktiv } = this.state.allUsers[userIndex];
        if (name && eMail) {
            const updatedUser = {
                u_id: id,
                u_name: name,
                u_email: eMail,
                u_rolle: rolle,
                u_aktiv: aktiv
            }
            console.log(updatedUser)
            axios.patch('/user', updatedUser)
                .then(response => {
                    this.setState({ allUsers: users });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    render() {
        let allUser = '';
        if (this.state.allUsers !== null) {
            if (this.state.lastDelete) {
                allUser = (
                    <div className="alert alert-danger" role="alert">
                        <h4 className="alert-heading">Letzter User</h4>
                        <p>Der letzte User darf nicht gelöscht werden.</p>
                    </div>
                );
            } else {
                allUser = this.state.allUsers.map(user => {
                    let disabler = false;
                    if (user.id === +this.props.aktID) {
                        disabler = true;
                    }
                    return (
                        <React.Fragment key={user.id}>
                            <form onSubmit={(event) => this.handleSubmit(event, user.id)}>
                                <div className="form-row align-items-center">
                                    <div className="col-sm-3 my-1">
                                        <label className="sr-only" htmlFor={'name' + user.id}>Username</label>
                                        <input type="text" className="form-control" id={'name' + user.id} name="name" value={user.name} onChange={(event) => this.usernameChangeHandler(event, user.id)} />
                                    </div>
                                    <div className="col-sm-3 my-1">
                                        <label className="sr-only" htmlFor={'email' + user.id}>e-Mail</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">@</div>
                                            </div>
                                            <input type="text" className="form-control" id={'email' + user.id} name="eMail" value={user.eMail} onChange={(event) => this.eMailChangeHandler(event, user.id)} />
                                        </div>
                                    </div>
                                    <div className="col-auto my-1">
                                        <label className="mr-sm-2 sr-only" htmlFor={'rolle' + user.id}>Rolle</label>
                                        <select disabled={disabler} className="custom-select mr-sm-2" id={'rolle' + user.id} name="rolle" value={user.rolle} onChange={(event) => this.rolleChangeHandler(event, user.id)}>
                                            <option defaultValue>Wähle...</option>
                                            <option value="1">Admin</option>
                                            <option value="2">Schreiber</option>
                                            <option value="3">User</option>
                                        </select>
                                    </div>
                                    <div className="col-auto my-1">
                                        <div className="form-check">
                                            <input disabled={disabler} className="form-check-input" type="checkbox" name="aktiv" id={'aktiv' + user.id} checked={user.aktiv === 1} onChange={() => this.aktivChangeHandler(user.id)} />
                                            <label className="form-check-label" htmlFor={'aktiv' + user.id}>
                                                Aktiv
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-auto my-1">
                                        <button type="submit" className="btn btn-primary">Speichern</button>
                                    </div>
                                    <div className="col-auto my-1">
                                        <button disabled={disabler} type="button" onClick={this.toggleDialog} className="btn btn-primary">Löschen</button>
                                    </div>
                                    {user.saved && <CheckCircleOutlineIcon style={{ color: green[500] }}></CheckCircleOutlineIcon>}
                                </div>
                            </form>
                            <Dialog open={this.state.showDialog}
                                frage='Benutzer löschen?'
                                text='Alle Daten dieses Benutzers werden gelöscht?'
                                buttonContinue='abbrechen'
                                buttonExit='fortsetzen'
                                continue={this.toggleDialog}
                                exit={() => this.deleteUser(user.id)} />
                        </React.Fragment>
                    );
                })
            }
        }


        return (
            <React.Fragment>
                {allUser}
            </React.Fragment>
        )
    }
}


const mapStateToProps = state => {
    return {
        aktID: state.users.u_id,
    }
};


export default connect(mapStateToProps)(OptionenUser);