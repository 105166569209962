import React from 'react';
import Button from '@material-ui/core/Button';

const button = (props) => (
        <Button 
            variant={props.btnType}
            color={props.btnColor}
            onClick={props.clicked} >
            {props.children}                       
        </Button>
);

export default button;