import React, { Component } from 'react';
import { connect } from 'react-redux';

import Modal from '../../components/UI/Modal/Modal';
import axios from '../../axios';
import AxiosImage from '../../components/UI/AxiosImage/AxiosImage';
import classes from './Dokumentation.module.css';
import DokuNew from '../../components/Dokumentationen/DokuNew/DokuNew';
import Spinner from './../../components/UI/Spinner/Spinner';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import Moment from 'react-moment';

class Dokumentation extends Component {
    state = {
        alleDokus: [],
        dokumentationen: [],
        showDokuDetail: false,
        error: false,
        selectedDokuId: null,
        selectedDokuNR: null,
        dokuNew: true,
        loading: false,

        total: 0,
        currentCount: 0,
        offset: 6,
        isFetching: false,

        screenWidth: 0
    }

    componentDidMount() {
        this.setState( {screenWidth: window.innerWidth});
        window.addEventListener('resize', this.handleResize)
        window.addEventListener('scroll', this.loadOnScroll);
        this.initMyDokus();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.loadOnScroll);
        window.removeEventListener('resize', this.handleResize);
    }
    handleResize = () => {
     
        this.setState( {screenWidth: window.innerWidth});
    }

    initMyDokus = () => {
        this.setState({ loading: true });
        axios.get('/dokus')
            .then(response => {
                const tempdokus = response.data.slice(0, 10);
                this.setState({
                    dokumentationen: tempdokus,
                    alleDokus: response.data,
                    loading: false,
                    total: response.data.length,
                    currentCount: 10
                });
            })
            .catch(error => {
                console.log(error);
                this.setState({ error: true, loading: false });
            });
    }

    loadOnScroll = (e) => {
        //If all the content loaded
        if (this.state.currentCount >= this.state.total) return;

        //Get div at the bottom of the content
        var el = document.getElementById('content-end');

        var rect = el.getBoundingClientRect();
        var isAtEnd = (
            // rect.top >= 0 &&
            // rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
            rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
        );

        //User at the end of content. load more content
        if (isAtEnd) {
            //If content list is still loading do not request for another content list.
            if (this.state.isFetching) return
            
            this.setState({ isFetching: true });
            const nextPosts = this.state.currentCount + this.state.offset;
            const tempdokus = this.state.alleDokus.slice(0, nextPosts);
            this.setState({ dokumentationen: tempdokus, currentCount: nextPosts });
            console.log(this.state.currentCount + ' ' + this.state.total)
            //On AJAX request success
            this.setState({ isFetching: false });
            //Update content list
        }
    }

    showDokuHandler = (id, nr) => {
        this.setState({
            selectedDokuId: id,
            selectedDokuNR: nr,
            showDokuDetail: true,
        });
    }

    hideDokuHandler = (id) => {
        axios.get('/dokus/' + id)
            .then(response => {
                console.log(response)
                const dokuIndex = this.state.alleDokus.findIndex(p => {
                    return p.d_id === id;
                });
                let kopieDokus = [...this.state.alleDokus];
                kopieDokus[dokuIndex].d_datum = new Date(response.data.d_datum);
                kopieDokus[dokuIndex].d_location = response.data.d_location;
                kopieDokus[dokuIndex].d_nr = response.data.d_nr;
                kopieDokus[dokuIndex].reloadImg = true;
                let tempdokus = kopieDokus.slice(0, this.state.currentCount);
                this.setState({ alleDokus: kopieDokus, dokumentationen: tempdokus });

                kopieDokus[dokuIndex].reloadImg = null;
                tempdokus = kopieDokus.slice(0, this.state.currentCount);
                this.setState({ alleDokus: kopieDokus, dokumentationen: tempdokus });
            })
            .catch(err => {
                console.log(err)
                const dokuIndex = this.state.alleDokus.findIndex(p => {
                    return p.d_id === id;
                });
                let kopieDokus = [...this.state.alleDokus];
                kopieDokus.splice(dokuIndex, 1);
                const neuesTotal = this.state.total - 1;
                this.setState({
                    alleDokus: kopieDokus,
                    dokumentationen: kopieDokus,
                    total: neuesTotal
                });
            }

            )
        this.setState({ showDokuDetail: false });
    }

    addNewDoku = () => {
        this.setState({ loading: true });
        axios.post('/dokunew', { wann: new Date() })
            .then(response => {
                console.log(response.data)
                this.setState({
                    selectedDokuId: response.data.id,
                    selectedDokuNR: response.data.nr,
                    showDokuDetail: true,
                });
                this.initMyDokus();
            })
            .catch(error => {
                console.log(error)
            })
        this.setState({ loading: false });
    }

    render() {
        let dokus = <p style={{ textAlign: 'center' }}>Something went wrong!</p>;
        if (!this.state.error) {
            if (this.state.loading) {
                dokus = <Spinner />;
            } else {
                dokus = (
                    <div className={classes.content}>
                        <div className={classes.overAll}>
                            <div className={classes.dokuContainer}>
                                <GridList spacing={4} cols={this.state.screenWidth < 650 ? 1 : 2} cellHeight={180} className={classes.gridList}>
                                    {this.state.dokumentationen.map((tile) => {
                                        return (
                                            <GridListTile
                                                key={tile.d_id}
                                                onClick={() => this.showDokuHandler(tile.d_id, tile.d_nr)}
                                                style={{ cursor: 'pointer' }}>
                                                {!tile.reloadImg ? <AxiosImage alt={tile.d_nr + ' .Stammtisch'} dokuID={tile.d_id}></AxiosImage> : null}
                                                <GridListTileBar
                                                    title={tile.d_nr + ' .Stammtisch'}
                                                    subtitle={<span><Moment format="DD.MM.YYYY">{tile.d_datum}</Moment> - {tile.d_location}</span>}
                                                />
                                            </GridListTile>
                                        )
                                    })}
                                </GridList>

                                { /* Start load more content when this div is visible*/

                                    (this.state.currentCount < this.state.total) ?
                                        <div id="content-end" >
                                            Please wait. Loading...
                                        </div> : null
                                }
                            </div>
                        </div>
                    </div>
                );
            }
        }

        let plusDoku = '';
        if (this.props.userRolle < 3) {
            plusDoku = (
                <div className={classes.Doku}
                    onClick={this.addNewDoku}>
                    <h2>+</h2>
                </div>
            );
        }

        let newDoku = '';
        if (this.state.showDokuDetail) {
            newDoku = (
                <DokuNew
                    dokuID={this.state.selectedDokuId}
                    dokuNR={this.state.selectedDokuNR}
                    dokuNew={this.state.dokuNew}
                    userRolle={this.props.userRolle}
                    userID={this.props.userID}
                    dokuAusblenden={this.hideDokuHandler} />
            );
        }

        return (
            <React.Fragment>
                <Modal show={this.state.showDokuDetail}>
                    {newDoku}
                </Modal>
                {plusDoku}
                {dokus}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        userRolle: state.users.u_rolle,
        userID: state.users.u_id,
    };
}

export default connect(mapStateToProps)(Dokumentation);