import React, { useState, useEffect } from 'react';
import axios from '../../axios';
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';

import Spinner from './../../components/UI/Spinner/Spinner';
import classes from './Logger.module.css';
import LogOverview from './../../components/Log/LogOverview';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';

const Logger = (props) => {
    const [showAdd, setShowAdd] = useState(true);
    const [loading, setLoading] = useState(false);
    const [myLogs, setMyLogs] = useState(null)
    const [location, setLocation] = useState('')
    const [locationError, setLocationError] = useState(false)
    const userID = useSelector(state => state.users.u_id);


    useEffect(() => {
        setLoading(true);
        axios.get('/loggers/' + userID)
            .then(response => {
                setMyLogs(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
                setMyLogs(null);
            });
        axios.get('/laststammtisch')
            .then(response => {
                setLocation(response.data.d_location)
            })
            .catch(error => {
                console.log(error)
            })

    }, [userID]);

    /* useEffect(() => {
         const timer = setTimeout(() => {
             //tu was nach dem timeout
         }, 500);
         return () => {
             clearTimeout(timer); //cleanup vom alten useEffekt, ohne dependencies ist das ein on unmount
         };
     }, [dependencies]); */

    const addNewLog = () => {
        if (location === '') {
            setLocationError(true)
        } else {
            const newDate = new Date();
            axios.post('/lognew', { wann: newDate, wer: userID, location: location })
                .then(response => {
                    const kopyLogs = myLogs;

                    kopyLogs.unshift({
                        'id': response.data.id.insertId,
                        'u_id': userID,
                        'create_date': newDate,
                        'location': location,
                        'close_date': null,
                        'closed': null,
                        'total_price': null,
                    })
                    setMyLogs([...kopyLogs]);
                    setShowAdd(false);
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    const saveLocation = (value) => {
        setLocation(value);
        setLocationError(false)
    }

    let logs = <p style={{ fontStyle: 'italic', textAlign: 'center' }}>Noch keine Logs vorhanden</p>;
    if (myLogs) {
        logs = myLogs.map(log => {
            return <LogOverview key={log.id} content={log} />
        });
    }
    let inhalt = (
        <React.Fragment>
            {logs}
        </React.Fragment>
    );
    if (loading) {
        inhalt = (
            <Spinner />
        );
    }
    return (
        <React.Fragment>
            {showAdd ? 
            <div className={classes.Addlog}>
                <div>
                    <TextField
                        error={locationError}
                        label='Location'
                        onChange={(event) => saveLocation(event.target.value)}
                        value={location}
                    /></div>
                <div>
                    <IconButton onClick={addNewLog} aria-label={'Add'} style={{ color: 'rgba(0, 0, 0, 0.70)' }}>
                        <AddIcon />
                    </IconButton>
                </div>
            </div>:null
            }
            <div className={classes.content}>
                <div className={classes.overAll}>
                    {inhalt}
                </div>
            </div>
        </React.Fragment>
    );

}

export default Logger;