import React, { useState } from 'react';
import classes from './LogOverview.module.css';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from '../../axios';
import LogItem from './LogItem';
import Spinner from '../UI/Spinner/Spinner';
import Dialog from '../UI/Dialog/Dialog';

const LogOverview = (props) => {
    const [showDetail, setShowDetail] = useState(false);
    const [myLogItems, setMyLogItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [kosumobjekt, setKonsumobjekt] = useState('');
    const [preis, setPreis] = useState('');
    const [itemError, setItemError] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [deleted, setDeleted] = useState(false)

    const addItem = () => {
        if (kosumobjekt === '') {
            setItemError(true)
        } else {

            axios.post('/logadditem', { l_id: props.content.id, name: kosumobjekt, price: preis })
                .then(response => {
                    console.log(response.data)
                    const kopyItem = myLogItems;

                    kopyItem.push({
                        'id': response.data.id.insertId,
                        'l_id': props.content.id,
                        'name': kosumobjekt,
                        'amount': 1,
                        'price': preis,
                    })
                    setMyLogItems([...kopyItem])
                    setKonsumobjekt('');
                    setPreis('');
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    const saveItem = (value) => {
        setKonsumobjekt(value);
        setItemError(false)
    }

    const detail = (
        <div className={classes.allOver}>
            {//<TextField
             //   label='Location'
             //   onChange={(event) => setLocation(event.target.value)}
             //   value={location}
             ///>
            }
            {myLogItems ? myLogItems.map(item => {
                return <LogItem key={item.id} content={item} />
            }) : null}
            <div className={classes.allOver} style={{ width: "90%", marginTop: '10px', padding: '10px' }}>
                <div className="row">
                    <div className="col-8">
                        <TextField
                            label='Konsumobjekt'
                            error={itemError}
                            onChange={(event) => saveItem(event.target.value)}
                            value={kosumobjekt}
                        />
                    </div>
                    <div className="col-4">
                        <TextField
                            type="number"
                            label='Preis'
                            onChange={(event) => setPreis(event.target.value)}
                            value={preis}
                        />
                    </div>
                </div>
                <div>
                    <IconButton onClick={addItem} aria-label={'Add Item'} style={{ color: 'rgba(0, 0, 0, 0.70)' }}>
                        <AddIcon />
                    </IconButton>
                </div>
            </div>

        </div>
    );

    const ShowMyDetails = () => {
        if (showDetail) {
            setShowDetail(false)
        } else {
            setLoading(true);
            axios.get('/loggeritems/' + props.content.id)
                .then(response => {
                    setMyLogItems(response.data);
                    setLoading(false);
                    setShowDetail(true)
                })
                .catch(error => {
                    console.log(error);
                    setMyLogItems(null);
                });
        }


    }

    const showMyDialog = (e) => {
        e.stopPropagation();
        setShowDialog(true)
    }

    const deleteLog = (e) => {
        setShowDialog(false)
        axios.delete('/logmain/' + props.content.id)
            .then(response => {
                console.log(response)
                setDeleted(true);
            })
            .catch(error => {
                console.log(error)
            })
    }
    if (deleted) {
        return null
    } else {
        return (
            <React.Fragment>
                <div className={classes.Overview} onClick={ShowMyDetails}>
                    <div className="row">
                        <div className="col-10">
                            <h5>{new Date(props.content.create_date).toLocaleDateString("de-DE")} - {props.content.location}</h5>
                        </div>
                        <div className="col-2">
                            <IconButton onClick={(event) => showMyDialog(event)} aria-label={'Add Item'} style={{ color: 'rgba(0, 0, 0, 0.70)' }}>
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    </div>
                </div>
                {loading ? <Spinner /> : null}
                {showDetail ? detail : null}
                <Dialog
                    frage='Soll das Log wirklich gelöscht werden?'
                    buttonContinue='Ja'
                    buttonExit='Nein'
                    open={showDialog}
                    continue={deleteLog}
                    exit={() => setShowDialog(false)}
                />
            </React.Fragment>
        );
    }

}

export default LogOverview;