import React from 'react';
import { Link } from 'react-router-dom';
import axios from '../../axios';

class RegisterPage extends React.Component {
    state = {
        user: {
            username: '',
            eMail: '',
            password: '',
            passwordCheck: '',
            passwordValid: false
        },
        submitted: false,
        registered: false,
        userDuplicate: false,
        eMailDuplicate: false
    };

    handleChange = (event) => {
        const { name, value } = event.target;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value,
                submitted: false
            }
        });
    }

    passwordValidation = (event) => {
        const { name, value } = event.target;
        const { user } = this.state;
        let validated;
        value === user.password ? validated = true : validated = false;
        this.setState({
            user: {
                ...user,
                [name]: value,
                passwordValid: validated
            }
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({ submitted: true });
        const { user } = this.state;
        if (user.eMail && user.username && user.password && user.passwordValid) {
            axios.post('/user', user)
                .then(response => {
                    console.log(response);
                    this.setState({ registered: true });
                })
                .catch(error => {
                    if (error.response.data === 'Error: DUPLICATE_USERNAME') {
                        this.setState({ userDuplicate: true });
                    } else if (error.response.data === 'Error: DUPLICATE_EMAIL') {
                        this.setState({ eMailDuplicate: true });
                    }
                });
        }
    }

    render() {
        //const { registering } = this.props;
        const { user, submitted } = this.state;
        let userError;
        this.state.userDuplicate ? userError = 'Dieser Name ist bereits vergeben!' : userError = 'Bitte einen Usernamen angeben!'
        let eMailError;
        this.state.eMailDuplicate ? eMailError = 'Dieser E-Mailadresse ist bereits vergeben!' : eMailError = 'Bitte eine E-Mailadresse angeben!'
        let registrierung;
        if (this.state.registered) {
            registrierung = (
                <div className="alert alert-success" role="alert">
                    <h4 className="alert-heading">Registrierung erfolgreich!</h4>
                    <p>Sie werden so schnell wie möglich vom Administrator freigeschalten.</p>
                    <hr></hr>
                    <p className="mb-0">Versprochen.</p>
                </div>
            );
        } else {
            registrierung = (
                <React.Fragment>
                    <h2>Register</h2>
                    <form className={'needs-validation' + (submitted ? ' was-validated' : '')} noValidate name="form" onSubmit={this.handleSubmit}>
                        <div className="form-group row">
                            <label htmlFor="username" className="col-sm-2 col-form-label">Username</label>
                            <div className="col-sm-10">
                                <input type="text" className={'form-control' + (this.state.userDuplicate ? ' is-invalid' : '')} name="username" value={user.username} onChange={this.handleChange} required />
                                <div className="invalid-feedback">
                                    {userError}
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="eMail" className="col-sm-2 col-form-label">E-Mail</label>
                            <div className="col-sm-10">
                                <input type="email" className={'form-control' + (this.state.eMailDuplicate ? ' is-invalid' : '')} name="eMail" value={user.eMail} onChange={this.handleChange} required />
                                <div className="invalid-feedback">
                                    {eMailError}
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="password" className="col-sm-2 col-form-label">Passwort</label>
                            <div className="col-sm-10">
                                <input type="password" className="form-control" name="password" value={user.password} onChange={this.handleChange} required />
                                <div className="invalid-feedback">
                                    Bitte ein Passwort angeben!
                            </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="passwordCheck" className="col-sm-2 col-form-label">Passwort wiederholen</label>
                            <div className="col-sm-10">
                                <input type="password" className={'form-control' + (user.passwordValid ? ' is-valid' : '')} name="passwordCheck" value={user.passwordCheck} onChange={this.passwordValidation} required />
                                <div className="invalid-feedback">
                                    Passwörter stimmen nicht überein!
                            </div>
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-sm-10">
                                <button className="btn btn-primary">Register</button>

                                <Link to="/login" className="btn btn-link">Abbrechen</Link>
                            </div>
                        </div>
                    </form >
                </React.Fragment>
            );
        }


        return (
            <div className="jumbotron">
                <div className="container">
                    {registrierung}
                </div>
            </div >
        );
    }
}


export default RegisterPage;