import React,{ Component } from 'react';
//import Aux from '../../../hoc/Auxilliary';
import Checkbox from '@material-ui/core/Checkbox';

class checkbox extends Component {
    render() {
        return (
            <label style={{    
                display: 'inline-flex',
                cursor: 'pointer',
                position: 'relative'
            }}>
                <Checkbox 
                    disabled={this.props.cbDisabled}
                    checked={this.props.cbChecked}
                    color={this.props.cbColor}
                    onChange={this.props.clicked} 
                    
                    >
                    {this.props.children}           
                </Checkbox>
                <span style ={{padding: '0.8rem 0.25rem'}}>{this.props.label}</span>
            </label>
        );
    }
};

export default checkbox;