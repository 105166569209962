import React from 'react';

import bertlerLogo from '../../assets/images/original.png';
import classes from './Logo.module.css';

const logo = (props) => (
    <div className={classes.Logo} style ={{height: props.height}}>
        <img src={bertlerLogo} alt ="Bertler Stammtisch"/>
    </div>
);

export default logo;