import React, { Component } from 'react';
import axios from '../../../axios';
import defaultImg from '../../../assets/images/defaultheader.jpg';

class AxiosImage extends Component {
    state = {
        loading: false,
        image: null
    }
    componentDidMount() {
        this.getMyImage();
    };

    getMyImage = () => {
        this.setState({ loading: true });
        if (this.props.dokuID) {
            axios.get('/getdocdokutitle/' + this.props.dokuID, {
                responseType: "arraybuffer"
            })
                .then(response => {
                    let data = `data:${
                        response.headers["content-type"]
                        };base64,${new Buffer(response.data, "binary").toString("base64")}`;
                    this.setState({ loading: false, image: data });
                })
                .catch(e => {
                    this.setState({ loading: false, image: defaultImg });
                });
        }
        if (this.props.docID) {
            axios.get('/files/' + this.props.docID, {
                responseType: "arraybuffer"
            })
                .then(response => {
                    let data = `data:${
                        response.headers["content-type"]
                        };base64,${new Buffer(response.data, "binary").toString("base64")}`;
                    this.setState({ loading: false, image: data });
                    this.props.sendBack({ id: this.props.docID, img: data });
                })
                .catch(e => {
                    this.setState({ loading: false, image: defaultImg });
                    this.props.sendBack({ id: this.props.docID, img: defaultImg });
                });
        }
    }

    render() {
        let bild = '';
        if (this.state.image) {
            bild = <img src={this.state.image} alt={this.props.alt} style={{ maxWidth: '110%', objectFit: 'contain' }} />
        } else {
            if (this.state.loading) {
                bild = <img alt='' src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            }
        }

        return <React.Fragment>{bild}</React.Fragment>
    }
}

export default AxiosImage;