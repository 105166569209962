import React, { useState } from "react";
import UploadService from "../../services/FileUploadService";
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import ImageCompression from 'browser-image-compression';

const UploadFiles = (props) => {
    const [currentFile, setCurrentFile] = useState(undefined);
    const [progress, setProgress] = useState(0);

    const options = { 
        maxSizeMB: 0.3,
        maxWidthOrHeight: 1024,   // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
        useWebWorker: true,
      }

    const selectFile = async (event) => {
        console.log(event.target.files)
        if (event.target.files.length > 0) {
            let currentFile = await ImageCompression(event.target.files[0], options);
            setProgress(0);
            setCurrentFile(currentFile);    
            const uploadFile = {file: currentFile, u_id: props.userID}
            UploadService.upload(uploadFile, (event) => {
                setProgress(Math.round((100 * event.loaded) / event.total));
            })
                .then((response) => {
                    props.onComplete(response.data.insertId)
                })
                .catch(() => {
                    setProgress(0);
                    setCurrentFile(undefined);
                    props.onComplete(0)
                });
        }
    };

    const inputEl = React.useRef(null);
    const onButtonClick = () => {
        // `current` points to the mounted file input element
        inputEl.current.click();
    };


    return (
        <React.Fragment>
            <input
                style={{ display: 'none' }}
                accept="image/*"
                type="file"
                id="upload-input"
                ref={inputEl}
                onChange={selectFile} />
            <label htmlFor="upload-input">
                <IconButton onClick={() => onButtonClick()} aria-label={'Bild bearbeiten'} style={{ color: props.theme === 'hell' ? 'rgba(0, 0, 0, 0.70)':'rgba(255, 255, 255, 0.70)' }}>
                    {props.icon === 'plus' ? <AddIcon /> : <EditIcon/>}
                </IconButton>
            </label>

            <div>
                {currentFile && (
                    <div className="progress">
                        <div
                            className="progress-bar progress-bar-info progress-bar-striped"
                            role="progressbar"
                            aria-valuenow={progress}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: progress + "%" }}
                        >
                            {progress}%
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};


export default UploadFiles;