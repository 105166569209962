import React from 'react';


const globalOptionenCheckbox = (props) => {

    return (
        <div className="form-group row">
            <div className="col-sm-10">
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id={props.id} name={props.name} checked={props.checked} onChange={props.onChange} />
                    <label className="form-check-label" htmlFor={props.id}>
                        {props.text}
                </label>
                </div>
            </div>
        </div>
    )
}

export default globalOptionenCheckbox;