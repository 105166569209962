import React, { useState, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import axios from '../../axios';

const LogOverview = (props) => {
    const [kosumobjekt, setKonsumobjekt] = useState('')
    const [preis, setPreis] = useState(0)
    const [menge, setMenge] = useState(1)
    const [deleted, setDeleted] = useState(false)


    useEffect(() => {
        setKonsumobjekt(props.content.name)
        setPreis(props.content.price)
        setMenge(props.content.amount)
    }, [props.content]);

    const IncAmount = () => {
        axios.patch('/logitemupdate', { id: props.content.id, amount: menge + 1 })
            .then(response => {
                setMenge(menge + 1)
            })
            .catch(error => {
                console.log(error)
            })
    }
    const DecAmount = () => {
        if (menge >= 1) {
            if ((menge - 1) === 0) {
                axios.delete('/logitemdel/' + props.content.id)
                    .then(response => {
                        setMenge(menge - 1)
                        setDeleted(true);                       
                    })
                    .catch(error => {
                        console.log(error)
                    })
            } else {
                axios.patch('/logitemupdate', { id: props.content.id, amount: menge - 1 })
                    .then(response => {
                        setMenge(menge - 1)
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        }
    }

    return (
        <React.Fragment>
            {!deleted ? 
            <div className="row">
                <div className="col">
                    <IconButton onClick={DecAmount} aria-label={'Add'} style={{ color: 'rgba(0, 0, 0, 0.70)' }}>
                        <RemoveIcon />
                    </IconButton>
                </div>
                <div className="col">
                    <h2>{menge}</h2>
                    <p>{kosumobjekt} ({menge * preis})</p>
                </div>
                <div className="col">
                    <IconButton onClick={IncAmount} aria-label={'Remove'} style={{ color: 'rgba(0, 0, 0, 0.70)' }}>
                        <AddIcon />
                    </IconButton>
                </div>
            </div>:null}
        </React.Fragment>
    );

}

export default LogOverview;