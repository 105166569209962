import React from 'react';
import classes from './Anwesend.module.css';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import axios from '../../../../axios';

class Anwesend extends React.Component {
    state = {
        stammtischID: 0,
        showAbwesende: false,
        allUsers: null,
        anwesende: [],
    }

    componentDidMount() {
        if (!this.state.allUsers) {
            axios.get('/activeuser/')
                .then(response => {
                    const alleuser = response.data;
                    let anwesende = alleuser.map(wer => {
                        return ({ id: wer.u_id, name: wer.u_name, anwesend: false, grund: '' });
                    });
                    const shuffledAnwesende = this.shuffleArray(anwesende)
                    this.setState({
                        allUsers: response.data,
                        anwesende: shuffledAnwesende,

                    });
                });
        }
    }

    componentDidUpdate() {
        if (this.props.dokuID) {
            if (this.state.stammtischID !== this.props.dokuID) {
                this.setState({ stammtischID: this.props.dokuID })

                axios.get('/dokuabwesenheit/' + this.props.dokuID)
                    .then(responsedokua => {
                        if (responsedokua.data.length > 0) {
                            const kAnwesende = responsedokua.data.map(wer => {
                                let da = wer.dab_anwesend === 0 ? false : true;
                                const userIndex = this.state.allUsers.findIndex(p => {
                                    return p.u_id === wer.u_id;
                                });

                                return ({ id: wer.u_id, name: this.state.allUsers[userIndex].u_name, anwesend: da, grund: wer.dab_grund });
                            });
                            this.setState({ anwesende: kAnwesende });
                        } else {
                            const kAnwesende = [...this.state.anwesende];
                            for (let anw in kAnwesende) {
                                kAnwesende[anw].anwesend = false;
                                kAnwesende[anw].grund = '';
                            }
                            this.setState({ anwesende: kAnwesende });
                        }
                    
                    })
                    .catch(error => console.log(error));
            }
        }
    }

    shuffleArray = (array) => {
        let i = array.length - 1;
        for (; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          const temp = array[i];
          array[i] = array[j];
          array[j] = temp;
        }
        return array;
      }

    showAbwesende = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ showAbwesende: true });
    }

    saveAbwesende = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ showAbwesende: false });
        this.abwesendeSpeichern();
    }

    abwesendeSpeichern = () => {
        axios.post('/dokuabwesenheit/' + this.props.dokuID, this.state.anwesende)
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error);
            });
    }

    checkboxToggler = (id) => {
        const anwesendeIndex = this.state.anwesende.findIndex(p => {
            return p.id === id;
        });
        const anwesende = [...this.state.anwesende];
        anwesende[anwesendeIndex].anwesend = !anwesende[anwesendeIndex].anwesend;
        if (anwesende[anwesendeIndex].anwesend) {
            anwesende[anwesendeIndex].grund = '';
        }
        this.setState({ anwesende: anwesende });
        this.abwesendeSpeichern();
    }

    abwesenheitChanger = (event, id) => {
        const anwesendeIndex = this.state.anwesende.findIndex(p => {
            return p.id === id;
        });
        const anwesende = [...this.state.anwesende];
        anwesende[anwesendeIndex].grund = event.target.value;
        this.setState({ anwesende: anwesende });
    }

    render() {
        let mindeiner = false;
        let abwesende;
        let anwesend = <p style={{ textAlign: 'center' }}>Loading...</p>;
        if (this.state.anwesende) {
            for (let i = 0; i < this.state.anwesende.length; i++) {
                if (!this.state.anwesende[i].anwesend) {
                    mindeiner = true;
                };
            }
            let button;
            if (this.props.userRolle < 3) {
                button = <div className={classes.wrapdiv}><Button color="primary" variant="text" onClick={this.showAbwesende}>Abwesenheit begründen</Button></div>;
            }
            anwesend = (
                <React.Fragment>
                    <div className={classes.wrapdiv}><span><strong>Anwesend:</strong></span> </div>
                    <form name="anwesende" onSubmit={this.showAbwesende}>
                        {this.state.anwesende.map(users => {
                            let clabel = users.name;
                            if (users.grund !== '' && !users.anwesend) {
                                clabel = users.name + ' (' + users.grund + ')';
                            }
                            return (
                                <FormControlLabel
                                    key={users.id}
                                    control={
                                        <Checkbox
                                            checked={users.anwesend}
                                            onChange={() => this.checkboxToggler(users.id)}
                                            name="checkedB"
                                            color="primary"
                                            disabled={this.props.userRolle < 3 ? false : true}
                                        />
                                    }
                                    label={clabel} />
                            )
                        })
                        }
                        <button style={{ display: 'none' }} className="btn btn-link"></button>
                        {!this.state.showAbwesende && mindeiner && button}
                    </form>
                </React.Fragment>
            )
        }

        const buttonSave = <div className={classes.wrapdiv}><Button color="primary" variant="text" onClick={this.saveAbwesende}>Begründung übernehmen</Button></div>;
        if (mindeiner) {
            abwesende = (
                <React.Fragment>
                    <div className={classes.wrapdiv}><span><strong>Begründung:</strong></span> </div>
                    <form name="form" onSubmit={this.saveAbwesende}>
                        {this.state.anwesende.map(users => {
                            if (!users.anwesend) {
                                return (
                                    <div className="form-group" key={users.id}>
                                        <TextField
                                            id={users.name}
                                            label={users.name}
                                            value={users.grund}
                                            onChange={(event) => this.abwesenheitChanger(event, users.id)}
                                            fullWidth
                                        />
                                    </div>
                                )
                            } else return ('')
                        })}
                        <button style={{ display: 'none' }} className="btn btn-link"></button>
                        {this.state.showAbwesende ? buttonSave : null}
                    </form>

                </React.Fragment>
            )
        } else abwesende = '';


        return (
            <div className={classes.alodiv}>
                {anwesend}
                {this.state.showAbwesende ? abwesende : null}
            </div>
        )
    }
};

export default Anwesend;