import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    loadedDoku: 0
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_DOKUID: return updateObject(state, {loadedDoku: action.id});
        default: return state;
    }
};

export default reducer;