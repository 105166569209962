
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import dokuReducer from './reducers/dokumentationen';
import userReducer from './reducers/users';
import serviceWorkerReducer from './reducers/serviceWorker';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    dokumentationen: dokuReducer,
    users: userReducer,
    serviceWorker: serviceWorkerReducer,
});

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

export default store;