import React from 'react';
import classes from './Antrag.module.css';
import Button from '../../../UI/Button/Button';
import Checkbox from '../../../UI/Checkbox/Checkbox';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import axios from '../../../../axios';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';

class Antrag extends React.Component {
    state = {
        antragError: false,
        antragGeberError: false,
        allUsers: null,
        stammtischID: 0,
        antrage: []
    }

    componentDidMount() {
        if (!this.state.allUsers) {
            axios.get('/activeuser/')
                .then(response => {
                    this.setState({ allUsers: response.data });
                });
        }
    }

    componentDidUpdate() {
        if (this.props.dokuID) {
            if (this.state.stammtischID !== this.props.dokuID) {
                this.setState({ stammtischID: this.props.dokuID })

                axios.get('/dokuantrag/' + this.props.dokuID)
                    .then(responseantrag => {
                        let dokuantrag = responseantrag.data.map(antrag => {
                            return ({
                                id: antrag.a_id,
                                antrag: antrag.d_antrag,
                                von_id: antrag.von_user,
                                angenommen: antrag.d_angenommen === 0 ? false : true,
                                stimmen: 0,
                                gespeichert: true
                            });
                        });
                        this.setState({ antrage: dokuantrag })
                    })
            }
        }
    }

    formSubmit = (e, id) => {
        e.preventDefault();
        e.stopPropagation();

        const antragIndex = this.state.antrage.findIndex(p => {
            return p.id === id;
        });

        if (this.state.antrage[antragIndex].antrag === '') {
            this.setState({ antragError: true })
        } else {
            this.setState({ antragError: false })
        }

        if (this.state.antrage[antragIndex].von_id === '') {
            this.setState({ antragGeberError: true })
        } else {
            this.setState({ antragGeberError: false })
        }

        if (this.state.antrage[antragIndex].antrag !== '' && this.state.antrage[antragIndex].von_id > 0) {
            this.AntragSpeichern(id);
        }
    }

    addAntrag = () => {
        const unsaved = this.state.antrage.findIndex(p => {
            return p.gespeichert === false;
        });
        if (unsaved < 0) {
            let kopieantrage = [...this.state.antrage];
            kopieantrage.push(
                {
                    'id': 0,
                    'antrag': '',
                    'von_id': '',
                    'angenommen': false,
                    'stimmen': 0,
                    'gespeichert': false
                }
            );
            this.setState({ antrage: kopieantrage });
        } else {
            this.setState({ antragError: true, antragGeberError: true })
        }
    }

    AntragTextChanger = (event, id) => {
        const antragIndex = this.state.antrage.findIndex(p => {
            return p.id === id;
        });
        const antrage = [...this.state.antrage];
        antrage[antragIndex].antrag = event.target.value;;
        this.setState({ antrage: antrage });
    }

    AntragVonChanger = (event, id) => {
        const antragIndex = this.state.antrage.findIndex(p => {
            return p.id === id;
        });
        const vonwem = event.target.value;
        const antrage = [...this.state.antrage];
        antrage[antragIndex].von_id = vonwem;
        this.setState({ antrage: antrage });
    }

    AntragCheckboxToggler = (id) => {
        const antragIndex = this.state.antrage.findIndex(p => {
            return p.id === id;
        });
        const antrage = [...this.state.antrage];
        antrage[antragIndex].angenommen = !antrage[antragIndex].angenommen;
        this.setState({ antrage: antrage });
    }

    AntragSpeichernToggle = (id) => {
        const antragIndex = this.state.antrage.findIndex(p => {
            return p.id === id;
        });
        const antrage = [...this.state.antrage];
        antrage[antragIndex].gespeichert = false;
        this.setState({ antrage: antrage });
    }

    AntragSpeichern = (id) => {
        const antragIndex = this.state.antrage.findIndex(p => {
            return p.id === id;
        });
        if (this.state.antrage[antragIndex].id === 0) {
            let antrag = {
                d_id: this.state.stammtischID,
                d_antrag: this.state.antrage[antragIndex].antrag,
                von_user: this.state.antrage[antragIndex].von_id,
                d_angenommen: this.state.antrage[antragIndex].angenommen ? 1 : 0
            }
            axios.post('/dokuantrag', antrag)
                .then(response => {
                    console.log(response.data.affectedRows.insertId);
                    const antrage = [...this.state.antrage];
                    antrage[antragIndex].gespeichert = true;
                    antrage[antragIndex].id = response.data.affectedRows.insertId;
                    this.setState({ antrage: antrage });
                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            let antrag = {
                a_id: this.state.antrage[antragIndex].id,
                d_id: this.state.stammtischID,
                d_antrag: this.state.antrage[antragIndex].antrag,
                von_user: this.state.antrage[antragIndex].von_id,
                d_angenommen: this.state.antrage[antragIndex].angenommen ? 1 : 0
            }
            axios.patch('/dokuantrag', antrag)
                .then(response => {
                    const antrage = [...this.state.antrage];
                    antrage[antragIndex].gespeichert = true;
                    this.setState({ antrage: antrage });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    render() {
        let antrage = '';
        let selectItems = [];
        if (this.state.allUsers) {
            for (let user in this.state.allUsers) {
                selectItems.push(
                    {
                        id: this.state.allUsers[user].u_id,
                        name: this.state.allUsers[user].u_name
                    }
                )
            }
        }
        if (this.state.antrage) {
            if (this.state.antrage.length !== 0) {
                antrage = this.state.antrage.map(antrag => {
                    if (!antrag.gespeichert) {
                        return (
                            <form onSubmit={(event) => this.formSubmit(event, antrag.id)} key={antrag.id}>
                                <div className={classes.dyncontainer}>
                                    <TextField
                                        //id="standard-search"
                                        label='Antrag'
                                        error={this.state.antragError}
                                        onChange={(event) => this.AntragTextChanger(event, antrag.id)}
                                        value={antrag.antrag}
                                        fullWidth
                                    />
                                    <div className={classes.flexcont}>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id={'antrag' + antrag.id}>von</InputLabel>
                                            <Select
                                                labelId={'antrag' + antrag.id}
                                                id={'id' + antrag.id}
                                                error={this.state.antragGeberError}
                                                value={antrag.von_id}
                                                onChange={(event) => this.AntragVonChanger(event, antrag.id)}
                                            >
                                                {selectItems.map(item => {
                                                    return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>

                                    </div>
                                    <div className={classes.flexcont}>
                                        <Checkbox
                                            clicked={() => this.AntragCheckboxToggler(antrag.id)}
                                            cbChecked={antrag.angenommen}
                                            cbColor="default"
                                            cbDisabled={false}
                                            label="Angenommen">
                                        </Checkbox>
                                    </div>
                                    <button style={{ display: 'none' }} className="btn btn-link"></button>
                                    <div className={classes.dyncontainer}><Button btnColor="primary" btnType="text" clicked={(event) => this.formSubmit(event, antrag.id)}>Antrag übernehmen</Button></div>
                                </div>
                            </form>
                        )
                    } else {
                        let angenommen = <strong> angenommen.</strong>;
                        if (!antrag.angenommen) {
                            angenommen = <strong> abgelehnt.</strong>;
                        }
                        const vonIndex = this.state.allUsers.findIndex(p => {
                            return p.u_id === antrag.von_id;
                        });
                        let neuer = (
                            <h5>
                                - Antrag von {this.state.allUsers[vonIndex].u_name}: {antrag.antrag}{angenommen}
                            </h5>
                        );

                        if (this.props.userRolle < 3) {
                            neuer = (
                                <h5 style={{ cursor: 'pointer' }} onClick={() => this.AntragSpeichernToggle(antrag.id)}>
                                    - Antrag von {this.state.allUsers[vonIndex].u_name}: {antrag.antrag}{angenommen}
                                </h5>
                            );
                        }

                        return (
                            <div className={classes.dyncontainer}
                                key={antrag.id}>
                                {neuer}
                            </div>
                        )
                    }
                })
            } else {
                antrage = '';
            }
        }
        let plusb = '';
        if (this.props.userRolle < 3) {
            plusb = (<div className={classes.wrapdiv}>
                <IconButton onClick={this.addAntrag} aria-label={'Antrag'} style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                    <AddIcon />
                </IconButton>
            </div>
            );
        }
        return (
            <div className={classes.alodiv}>
                <span><strong>Anträge:</strong></span>
                {antrage}
                {plusb}
            </div>
        )
    }
}

export default Antrag;