import React, { useState, useEffect } from "react";
import Carousel from 'react-bootstrap/Carousel';

const GallerySlider = (props) => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    useEffect(() => {
        setIndex(props.index);
    }, [props.index]);

    const items = props.galleryData.map(item => {
        return (
            <Carousel.Item key={item.id}>
                <img
                    className="d-block w-100"
                    style={{maxHeight:'90%' }}
                    src={item.img}
                    alt={item.id}
                />
            </Carousel.Item >
        )
    })

    return (
        <Carousel activeIndex={index} onSelect={handleSelect}>
            {items}
        </Carousel>
    );
}

export default GallerySlider;