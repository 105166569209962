import React, { Component } from 'react';
import Button from '../../../UI/Button/Button';
import classes from './StammtischNummer.module.css';
import TextField from '@material-ui/core/TextField';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import FileUpload from '../../../FileUpload/FileUpload';
import Moment from 'react-moment';
import AxiosImage from '../../../UI/AxiosImage/AxiosImage';
import axios from '../../../../axios';
import DatumPicker from '../../../UI/DatumPicker/DatumPicker';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

class StammtischNummer extends Component {
    
    state = {
        stammtischID: 0,
        stammtischnr: 0,
        tempstammtischnr: 0,
        editStammtischNr: false,
        wo: '',
        wann: new Date(),
        editWannWo: false,
        allUsers: null,
        showImage: false,
        infoVisible: false,
        infoAvailable: true,
        titleInfo: '',
        infoBar: 'transparent'
    }

    componentDidMount() {
        if (!this.state.allUsers) {
            axios.get('/activeuser/')
                .then(response => {
                    this.setState({ allUsers: response.data });
                });
        }
    }

    componentDidUpdate() {
        if (this.props.dokuID) {
            if (this.state.stammtischID !== this.props.dokuID) {
                axios.get('/dokus/' + this.props.dokuID)
                    .then(response => {
                        let editwo = response.data.d_location !== '' ? false : true;
                        this.setState({
                            stammtischID: response.data.d_id,
                            stammtischnr: response.data.d_nr,
                            tempstammtischnr: response.data.d_nr,
                            wo: response.data.d_location,
                            wann: new Date(response.data.d_datum),
                            editWannWo: editwo,
                            showImage: true,
                        })
                    })
            }
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    toggleTitleInfo = () => {
        if (!this.state.infoVisible) {
            axios.get('/getdocdokutitleinfo/' + this.props.dokuID)
                .then(response => {
                    let userIndex = -1;
                    userIndex = this.state.allUsers.findIndex(p => {
                        return p.u_id === response.data.u_id;
                    });
                    let uploadedVon = 'not set';
                    if (userIndex > -1) {
                        uploadedVon = this.state.allUsers[userIndex].u_name; 
                    }
                    let uploadedWann = 'not set';
                    if (response.data.doc_added) {
                        uploadedWann = new Date(response.data.doc_added).toLocaleDateString("de-DE")+' - '+new Date(response.data.doc_added).toLocaleTimeString("de-DE"); 
                    }
                    this.setState({
                        titleInfo: 'Upload: '+uploadedVon+' / '+uploadedWann})
                })
                .catch(err => {
                    this.setState({titleInfo: ''})
                })
            this.setState({ infoVisible: true, infoBar: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
            'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)', });
            this.timer = setTimeout(() => { this.setState({ infoVisible: false, infoBar: 'transparent' }); }, 5000);
        }
    }

    toggleStammtischNr = () => {
        this.setState({ showStammtischnr: !this.state.showStammtischnr });
    }

    saveStammtischNr = () => {
        this.setState({ tempstammtischnr: this.state.stammtischnr });
        this.setState({ showStammtischnr: false });
    }

    changeStammtischNr = event => {
        this.setState({ tempstammtischnr: event.target.value });
    }

    saveStammtischNr = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const stammnr = this.state.tempstammtischnr;
        this.setState({
            stammtischnr: stammnr,
            showStammtischnr: false
        });
        this.updateDokuDB();
    }

    ortChanger = (event) => {
        const ort = event.target.value;
        this.setState({ wo: ort });
    }
    datumChanger = (event) => {
        const datum = event;
        this.setState({ wann: datum });
    }
    wannwoToggle = (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({ editWannWo: !this.state.editWannWo });
        this.updateDokuDB();
    }

    handleOnUploadComplete = (value) => {
        axios.post('/docdoku', { doc_id: value, doku_id: this.state.stammtischID })
            .then(response => {
                axios.post('/docdokutitle', { doc_id: value, doku_id: this.state.stammtischID })
                    .then(response => {
                        console.log(response);
                        this.setState({ showImage: false });
                        this.setState({ showImage: true });
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(error => {
                console.log(error);
            });
    };

    updateDokuDB = () => {
        const updatedDoku = {
            d_id: this.state.stammtischID,
            d_nr: this.state.stammtischnr,
            d_location: this.state.wo,
            d_datum: this.state.wann,
        };

        axios.patch('/dokunew', updatedDoku)
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error);
            });
    };


    render() {
        let editStNr = '';
        if (this.state.showStammtischnr && this.props.userRolle < 3) {
            editStNr = (
                <form name="stammtischnummer" onSubmit={this.saveStammtischNr}>
                    <div className={classes.alodiv}>
                        <div className={classes.Zahldiv}>
                            <TextField
                                id="standard-number"
                                label='#. Stammtisch'
                                value={this.state.tempstammtischnr}
                                onChange={(event) => this.changeStammtischNr(event)}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                        <div className={classes.Zahldiv}>
                            <Button btnColor="secondary" btnType="text" clicked={this.toggleStammtischNr}>Abbrechen</Button>
                            <Button btnColor="primary" btnType="text" clicked={this.saveStammtischNr}>Speichern</Button>
                        </div>
                    </div>
                </form>
            )
        }

        let editWannWo = '';
        if (this.state.editWannWo && this.props.userRolle < 3) {
            editWannWo = (
                <form name="wannwo" onSubmit={this.wannwoToggle}>
                    <div className={classes.alodiv}>
                        <div className={classes.dyncontainer}>
                            <DatumPicker
                                Wann={this.state.wann}
                                Beschriftung={'Datum'}
                                changed={this.datumChanger} >
                            </DatumPicker>
                        </div>
                        <div className={classes.dyncontainer}>
                            <TextField
                                label='Ort'
                                onChange={this.ortChanger}
                                value={this.state.wo}
                                type="search"
                                fullWidth
                            />
                        </div>
                    </div>
                    <button style={{ display: 'none' }} className="btn btn-link"></button>
                    <div className={classes.wrapdiv}><Button btnColor="primary" btnType="text" clicked={this.wannwoToggle}>Ort und Datum übernehmen</Button></div>
                </form>
            )
        }
        let titleBar = (
            <GridListTileBar
                title={<div style={{ marginTop: '10px' }}>
                    <h4> {this.state.tempstammtischnr}. Stammtisch</h4>
                </div>}
                subtitle={<div>
                    <h5><Moment format="DD.MM.YYYY">{this.state.wann}</Moment> - {this.state.wo}</h5>
                </div>}
            />
        );
        if (this.props.userRolle < 3) {
            titleBar = (
                <GridListTileBar
                    title={<div style={{ marginTop: '10px' }} onClick={this.toggleStammtischNr}>
                        <h4 style={{ cursor: 'pointer' }}> {this.state.tempstammtischnr}. Stammtisch</h4>
                    </div>}
                    subtitle={<div onClick={this.wannwoToggle}>
                        <h5 style={{ cursor: 'pointer' }}><Moment format="DD.MM.YYYY">{this.state.wann}</Moment> - {this.state.wo}</h5>
                    </div>}

                    actionIcon={
                        <FileUpload theme='dunkel'icon='edit' userID={this.props.userID} onComplete={(value) => this.handleOnUploadComplete(value)} />
                    }
                />
            );
        }

        return (
            <React.Fragment>
                <GridList cellHeight={300} >
                    <GridListTile style={{ width: '100%' }}>
                        {this.state.showImage ? <AxiosImage alt={this.state.tempstammtischnr + ' .Stammtisch'} dokuID={this.props.dokuID}></AxiosImage> : null}
                        {this.state.infoAvailable ?
                            <GridListTileBar style={{ background: this.state.infoBar }}
                                titlePosition="top"
                                title={this.state.infoVisible ? this.state.titleInfo : ''}
                                actionIcon={
                                    <IconButton onClick={this.toggleTitleInfo} aria-label={`star test`} className={classes.icon}>
                                        <InfoIcon />
                                    </IconButton>
                                }
                            /> : null}
                        {titleBar}
                    </GridListTile>
                </GridList>
                <div>
                    {editStNr}
                </div>
                <div>
                    {editWannWo}
                </div>
            </React.Fragment>
        );
    }
};

export default StammtischNummer;