import React from 'react';
import { connect } from 'react-redux';
import axios from '../../axios';

import * as actions from '../../store/actions/index';
import GlobalOptionenCheckbox from '../../components/Optionen/globaleOptionenCheckbox/globaleOptionenCheckbox';
import OptionenUser from '../../components/Optionen/User/user';

class Einstellungen extends React.Component {
    state = {
        altesPasswort: '',
        neuesPasswort: '',
        neuesPasswortCheck: '',
        passwordValid: false,
        submitted: false,
        showReg: false,
        startValidation: false,
        allUsers: [],
    }
    componentDidMount() {
        axios.get('/option/1')
            .then(response => {
                let option = false;
                if (response.data.g_option === 1) {
                    option = true;
                }
                this.setState({ showReg: option });
            });


    }
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value,
        });
    }

    passwordValidation = (event) => {
        const { name, value } = event.target;
        const { neuesPasswort } = this.state;
        let validated;
        value === neuesPasswort ? validated = true : validated = false;
        this.setState({
            [name]: value,
            passwordValid: validated,
            startValidation: true
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({ submitted: true });
        const { altesPasswort, neuesPasswort, passwordValid } = this.state;
        if (altesPasswort && neuesPasswort && passwordValid) {
            const updatedUser = {
                u_id: this.props.aktUser,
                altesPasswort: altesPasswort,
                neuesPasswort: neuesPasswort
            }
            this.props.updateUser(updatedUser);
        }
    }
    
    checkboxChangeHandler = (e) => {
        const { name, checked, id } = e.target;
        this.setState({ [name]: checked });
        let option = 0
        checked ? option = 1 : option = 0;
        const updatedOption = {
            id: id,
            g_option: option
        }
        axios.patch('/updateoption/', updatedOption)
            .then(response => {
                if (response.data.status === 200) {

                }
                console.log(response);
            })
            .catch(error => {
                console.log(error);
            });
    }

    backToChange = () => {
        this.setState({
            altesPasswort: '',
            neuesPasswort: '',
            neuesPasswortCheck: '',
            passwordValid: false,
            submitted: false,
            startValidation: false
        });
        this.props.backToPWChange();
    }

    render() {
        let psChange = '';
        let allgUser = '';
        let admini = '';
        let savePW = '';
        if (this.state.passwordValid) {
            savePW = (
                <div className="form-group row">
                    <div className="col-sm-10">
                        <button className="btn btn-primary">Passwort Speichern</button>
                    </div>
                </div>
            );
        }


        if (+this.props.aktRolle === 1) {

            const showReg = (
                <React.Fragment>
                    <h5>Globale Optionen</h5>
                    <GlobalOptionenCheckbox
                        id='1'
                        name='showReg'
                        checked={this.state.showReg}
                        onChange={this.checkboxChangeHandler}
                        text='Register beim Login anzeigen'>
                    </GlobalOptionenCheckbox>
                </React.Fragment>
            )

            admini = (
                <React.Fragment>
                    <div className="jumbotron">
                        <div className="container">
                            <h4>Administratoroptionen</h4>
                            {showReg}
                            <OptionenUser />
                        </div>
                    </div>
                </React.Fragment>
            );
        }

        if (this.props.updateError) {
            psChange = (
                <div className="alert alert-danger" role="alert">
                    <h4 className="alert-heading">Etwas ist schief gelaufen</h4>
                    <p>Versuch es bitte später erneut.</p>
                    <hr></hr>
                    <button className="btn btn-primary" onClick={this.backToChange}>Passwort ändern</button>
                </div>
            );
        } else {
            if (this.props.updateDone) {
                psChange = (
                    <div className="alert alert-success" role="alert">
                        <h4 className="alert-heading">Passwort erfolgreich geändert</h4>
                        <p>Du kannst dich nun mit deinem neuen Passwort einloggen.</p>
                        <hr></hr>
                        <button className="btn btn-primary" onClick={this.backToChange}>Passwort ändern</button>
                    </div>
                );
            } else {
                psChange = (
                    <React.Fragment>
                        <h5>Passwort ändern</h5>
                        <form className={'needs-validation' + (this.state.submitted ? ' was-validated' : '')} noValidate name="form" onSubmit={this.handleSubmit}>
                            <div className="form-group row">
                                <label htmlFor="altesPasswort" className="col-sm-3 col-form-label">altes Passwort</label>
                                <div className="col-sm-9">
                                    <input type="password" className="form-control" name="altesPasswort" value={this.state.altesPasswort} onChange={this.handleChange} required />
                                    <div className="invalid-feedback">
                                        Bitte ein Passwort angeben!
                                </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="neuesPasswort" className="col-sm-3 col-form-label">neues Passwort</label>
                                <div className="col-sm-9">
                                    <input type="password" className="form-control" name="neuesPasswort" value={this.state.neuesPasswort} onChange={this.handleChange} required />
                                    <div className="invalid-feedback">
                                        Bitte ein Passwort angeben!
                                </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="neuesPasswortCheck" className="col-sm-3 col-form-label">neues Passwort wiederholen</label>
                                <div className="col-sm-9">
                                    <input type="password" className={'form-control' + (this.state.startValidation ? (this.state.passwordValid ? ' is-valid' : ' is-invalid') : '')} name="neuesPasswortCheck" value={this.state.neuesPasswortCheck} onChange={this.passwordValidation} required />
                                    <div className="invalid-feedback">
                                        Passwörter stimmen nicht überein!
                                </div>
                                </div>
                            </div>
                            {savePW}

                        </form >
                    </React.Fragment>
                );
            }
        }
        allgUser = (
            <React.Fragment>
                <div className="jumbotron">
                    <div className="container">
                        <h4>Benutzeroptionen</h4>
                        {psChange}
                    </div>
                </div>
            </React.Fragment>
        );

        return (
            <React.Fragment>
                {admini}
                {allgUser}
            </React.Fragment>

        );
    }
}


const mapStateToProps = state => {
    return {
        aktUser: state.users.u_id,
        aktRolle: state.users.u_rolle,
        updateError: state.users.error,
        updateDone: state.users.userUpdated
    }
};

const mapDispatchToProps = dispatch => {
    return {
        updateUser: (updatedUser) => dispatch(actions.updateUser(updatedUser)),
        backToPWChange: () => dispatch(actions.backToPWChange()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Einstellungen);