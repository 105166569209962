import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from './../../axios';

import * as actions from '../../store/actions/index';

class LoginPage extends React.Component {
    state = {
        username: '',
        password: '',
        submitted: false,
        showRegister: false,
        anmeldungSpeichern: false,
        passwortVergessen: false,
        passwortVergessenResponse: '',
        emailGesendet: false,
        redirectToDoku: false
    }

    componentDidMount() {
        axios.get('/option/1')
            .then(response => {
                let option = false;
                if (response.data.g_option === 1) {
                    option = true;
                }
                this.setState({ showRegister: option });
            });
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    checkboxChangeHandler = (e) => {
        this.setState({ anmeldungSpeichern: !this.state.anmeldungSpeichern });
    }
    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value, submitted: false });
        this.props.resetLoginError();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ submitted: true, passwortVergessenResponse: '', passwortVergessen: false });
        const { username, password, anmeldungSpeichern } = this.state;
        if (username && password) {
            const loginData = {
                username: username,
                password: password,
                anmeldungSpeichern: anmeldungSpeichern
            }
            this.props.login(loginData);

        }
    }
    passwortVergessenClick = () => {
        if (this.state.username) {
            // axios.post('/passwortvergessen', { name: this.state.username })
            //     .then(response => {
            //         console.log(response.data)
            //         this.setState({ emailGesendet: true });
            //     })
            //     .catch(error => {
            //         console.log(error)
            //     });

        }

    }

    render() {
        let redirect;
        if (this.props.match.path === '/logout' && !this.props.loggedIn) {
            redirect = <Redirect to="/login" />;
        }
        //const { loggingIn } = this.props;
        const { username, password, submitted } = this.state;
        let register = '';
        if (this.state.showRegister) {
            register = <Link to="/register" className="btn btn-link">Register</Link>
        }
        let loginError;

        let userError = false;
        let passwordError = false;
        let inaktivError = false;
        let fatalError = false;
        let passwortVergessen = false;
        let tokenError = false;
        switch (this.props.loginError) {
            case 'Error: USERNAME_404': {
                loginError = 'Der angegebene Benutzername wurde nicht gefunden';
                userError = true;
                break;
            }
            case 'Error: WRONG_PASSWORD': {
                loginError = 'Das Passwort ist ungültig';
                passwortVergessen = true;
                passwordError = true;
                break;
            }
            case 'Error: INACTIVE_USER': {
                loginError = 'Der Benutzer ist nicht aktiviert!';
                inaktivError = true;
                break;
            }
            //  case 'Error: TOKEN_404': {
            //      loginError = 'TOKEN_404';
            //      tokenError = true;
            //      break;
            //  }
              case 'Error: Token expired': {
                  loginError = 'TOKEN_408';
                  tokenError = true;
                  break;
              }
            default: {
                loginError = 'Eingabe ungültig';
                fatalError = true
            }
        }
        let tokenExp = <h2>Login</h2>;
        if (tokenError) {
            redirect = <Redirect to="/login" />;
            tokenExp = (
                <div className="alert alert-warning" role="alert">
                    <h4 className="alert-heading">Token abgelaufen </h4>
                    <p>Bitte logge dich neu ein.</p>
                </div>
            );

        }
        let login;
        if (this.state.emailGesendet) {
            login = (
                <div className="alert alert-success" role="alert">
                    <h4 className="alert-heading">E-Mail gesendet</h4>
                    <p>Eine E-mail mit den Anweisungen zur Wiederherstellung des Passwortes wurde versendet.</p>
                </div>
            );
        } else {
            if (fatalError && this.props.loginError !== '') {
                login = (
                    <div className="alert alert-danger" role="alert">
                        <h4 className="alert-heading">Etwas ist schrecklich schief gelaufen {this.state.username}</h4>
                        <p>Es scheint ein Problem mit dem Server zu geben. Bitte versuch es später erneut.</p>
                        <hr></hr>
                        <Link to="/login" className="btn btn-link">zum Login</Link>
                    </div>
                );
            } else {

                if (this.props.loggedIn) {

                    if (this.props.match.path === '/logout') {
                        login = (
                            <div className="alert alert-success" role="alert">
                                <h4 className="alert-heading">Auf Wiedersehen {this.props.aktUser}</h4>
                                <p>Bedenke, dass nicht gespeicherte Daten verloren gehen.</p>
                                <hr></hr>
                                <button className="btn btn-primary" onClick={this.props.logout}>Logout</button>
                            </div>
                        );
                    } else {
                        let gotoDoku = '';
                        if (this.state.redirectToDoku) { gotoDoku = <Redirect to="/dokumentationen" /> }
                        else {
                            gotoDoku = '';
                            this.timer = setTimeout(() => { this.setState({ redirectToDoku: true }) }, 2000);
                        }


                        login = (

                            <div className="alert alert-success" role="alert">
                                <h4 className="alert-heading">Hallo {this.props.aktUser}</h4>
                                <p>Der Login war erfolgreich. Du wirst gleich zu den Dokumentationen weitergeleitet</p>
                                <hr></hr>
                                <button className="btn btn-primary" onClick={this.props.logout}>Logout</button>
                                {gotoDoku}
                            </div>

                        );
                    }
                } else {
                    login = (
                        <React.Fragment>
                            {redirect}
                            
                            {tokenExp}
                            <form className={'needs-validation' + ((submitted && this.props.loginError === '') ? ' was-validated' : '')} noValidate name="form" onSubmit={this.handleSubmit}>

                                <div className='form-group row'>
                                    <label htmlFor="username" className="col-sm-2 col-form-label">Username</label>
                                    <div className="col-sm-10">
                                        <input type="text" className={'form-control' + (userError || inaktivError ? ' is-invalid' : '')} name="username" value={username} onChange={this.handleChange} required />
                                        <div className="invalid-feedback">
                                            {loginError}
                                        </div>
                                    </div>
                                </div>

                                <div className='form-group row'>
                                    <label htmlFor="password" className="col-sm-2 col-form-label">Passwort</label>
                                    <div className="col-sm-10">
                                        <input type="password" className={'form-control' + (passwordError || inaktivError ? ' is-invalid' : '')} name="password" value={password} onChange={this.handleChange} required />
                                        <div className="invalid-feedback">
                                            {loginError}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-sm-2 col-form-label"></div>
                                    <div className="col-sm-10">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="anmeldungSpeichern" name="anmeldungSpeichern" onChange={this.checkboxChangeHandler} />
                                            <label className="form-check-label" htmlFor="anmeldungSpeichern">
                                                Angemeldet bleiben
                                    </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <button className="btn btn-primary">Login</button>
                                    {this.props.loggingIn &&
                                        <img alt='' src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                    }
                                    {register}
                                    {passwortVergessen && <button type="button" className="btn btn-link" onClick={this.passwortVergessenClick}>Passwort vergessen</button>}
                                </div>
                            </form>
                        </React.Fragment>
                    );
                }
            }
        }



        return (
            <div className="jumbotron">
                <div className="container">
                    {login}
                </div>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        aktUser: state.users.u_name,
        loggingIn: state.users.loggingIn,
        loggedIn: state.users.loggedIn,
        loginError: state.users.error
    }
};

const mapDispatchToProps = dispatch => {
    return {
        login: (loginData) => dispatch(actions.loginUser(loginData)),
        logout: () => dispatch(actions.logoutUser()),
        resetLoginError: () => dispatch(actions.resetLoginError())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);