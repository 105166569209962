import axios from 'axios';

import store from './store/store';

let instance;
if (process.env.NODE_ENV !== 'production') {
    instance = axios.create({
        baseURL: 'http://localhost:10001'
    });
} else {
    //Production ist immer automatisch eingestellt, wenn via npm build die App erstellt wird für den Server
    instance = axios.create({
        baseURL: 'https://bertler-api.petermarkl.com'
    });
}

store.subscribe(() => {
    //console.log(store.getState().users)
    const token = store.getState().users.u_token;
    if (token) {
        instance.defaults.headers.common['Authorization'] = token;
    } else {
        instance.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
    }
});


// instance.interceptors.request...

export default instance;
