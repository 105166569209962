import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    serviceWorkerUpdated: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_SERVICEWORKER: return updateObject(state, {serviceWorkerUpdated: true});
        default: return state;
    }
};

export default reducer;