import React from 'react';
import axios from '../../axios';
import Spinner from '../../components/UI/Spinner/Spinner';
class ResetPassword extends React.Component {
    state = {
        userID: -1,
        token: '',
        error404: false,
        error408: false,
        errorUnbekannt: false,
        loading: true,
        neuesPasswort: '',
        neuesPasswortCheck: '',
        passwordValid: false,
        submitted: false,
        confirmed: false,

    };

    async componentDidMount() {
        await axios
            .get('/reset/' + this.props.match.params.token)
            .then(resp => {
                console.log(resp)
                this.setState({
                    loading: false,
                    userID: resp.data.u_id,
                    token: resp.data.token
                });
            })
            .catch(err => {
                switch (err.response.data) {
                    case 'Error: TOKEN_404': {
                        this.setState({ error404: true });
                        break;
                    }
                    case 'Error: TOKEN_408': {
                        this.setState({ error408: true });
                        break;
                    }
                    default: {
                        this.setState({ errorUnbekannt: true });
                    }
                }
                this.setState({ loading: false });
            })
    }
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value,
        });
    }
    passwordValidation = (event) => {
        const { name, value } = event.target;
        const { neuesPasswort } = this.state;
        let validated;
        value === neuesPasswort ? validated = true : validated = false;
        this.setState({
            [name]: value,
            passwordValid: validated,
            startValidation: true
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({ submitted: true });
        const { neuesPasswort, passwordValid } = this.state;
        if (neuesPasswort && passwordValid) {
            const updatedUser = {
                token: this.state.token,                
                u_id: this.state.userID,
                neuesPasswort: neuesPasswort
            }
            console.log(updatedUser)
            axios.patch('/userpw', updatedUser)
                .then(response => {
                    this.setState({ confirmed: true });
                })
                .catch(error => {
                    console.log(error.response)
                });
        }
    }

    render() {
        let renderer = <Spinner />;
        if (!this.state.loading) {
            renderer = (
                <React.Fragment>
                    <h5>Neues Passwort eingeben</h5>
                    <form className={'needs-validation' + (this.state.submitted ? ' was-validated' : '')} noValidate name="form" onSubmit={this.handleSubmit}>
                        <div className="form-group row">
                            <label htmlFor="neuesPasswort" className="col-sm-3 col-form-label">neues Passwort</label>
                            <div className="col-sm-9">
                                <input type="password" className="form-control" name="neuesPasswort" value={this.state.neuesPasswort} onChange={this.handleChange} required />
                                <div className="invalid-feedback">
                                    Bitte ein Passwort angeben!
                        </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="neuesPasswortCheck" className="col-sm-3 col-form-label">neues Passwort wiederholen</label>
                            <div className="col-sm-9">
                                <input type="password" className={'form-control' + (this.state.startValidation ? (this.state.passwordValid ? ' is-valid' : ' is-invalid') : '')} name="neuesPasswortCheck" value={this.state.neuesPasswortCheck} onChange={this.passwordValidation} required />
                                <div className="invalid-feedback">
                                    Passwörter stimmen nicht überein!
                        </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-10">
                                <button className="btn btn-primary">Passwort Speichern</button>
                            </div>
                        </div>

                    </form >
                </React.Fragment>
            );
        }


        if (this.state.confirmed) {
            renderer = (
                <div className='alert alert-success' role="alert">
                    <h4 className="alert-heading">Passwort aktualisiert</h4>
                    <p>Sie können sich nun mit Ihrem neuen Passwort einloggen.</p>
                </div>
            );
        }

        if (this.state.error404) {
            renderer = (
                <div className="alert alert-danger" role="alert">
                    <h4 className="alert-heading">Ungültige Anfrage</h4>
                    <p>Es wurde keine passende Passwortanfrage gefunden.</p>
                </div>
            );
        }
        if (this.state.error408) {
            renderer = (
                <div className="alert alert-danger" role="alert">
                    <h4 className="alert-heading">Anfrage abgelaufen</h4>
                    <p>Ihre Passwortanfrage ist abgelaufen, bitte stellen Sie eine Neue.</p>
                </div>
            );
        }

        return (
            <div className="jumbotron">
                <div className="container">
                    {renderer}
                </div>
            </div >
        );
    }
}


export default ResetPassword;