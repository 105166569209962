import React, { Component } from 'react';
import classes from './NextTermin.module.css';
import Button from '../../../UI/Button/Button';
import DatumPicker from '../../../UI/DatumPicker/DatumPicker';
import Moment from 'react-moment';
import axios from '../../../../axios';
import Roulette from '../Roulette/Roulette';
import Spinner from '../../../UI/Spinner/Spinner';

class NextTermin extends Component {
    state = {
        stammtischID: 0,
        allUsers: null,
        nextstamm: new Date(),
        tempnextstamm: new Date(),
        shownextstamm: true,
        antrage: [],
        showroulette: false,
        rouletteitems: [],
        nextorganisator: null,
        rLoading: false,
        event: {
            name: "Happy Hour",
            details: "Let's go after work",
            location: "Boston, MA",
            startsAt: "2018-12-06T17:00:00-05:00",
            endsAt: "2018-12-06T18:00:00-05:00"
          }
    }

    componentDidMount() {
        if (!this.state.allUsers) {
            axios.get('/activeuser/')
                .then(response => {
                    this.setState({ allUsers: response.data });
                });
        }
    }

    componentDidUpdate() {
        if (this.props.dokuID) {
            if (this.state.stammtischID !== this.props.dokuID) {
                this.setState({ stammtischID: this.props.dokuID })
                axios.get('/dokuzusatz/' + this.props.dokuID)
                    .then(responsedokuzusatz => {
                        let nextStamm = new Date();
                        let nextOrga = '-';

                        nextStamm = new Date(responsedokuzusatz.data.d_nextdatum);
                        let userIndex = -1;
                        userIndex = this.state.allUsers.findIndex(p => {
                            return p.u_id === responsedokuzusatz.data.d_organisator;
                        });
                        if (userIndex === -1) {
                            nextOrga = '-';
                        } else {
                            nextOrga = this.state.allUsers[userIndex].u_name;
                        }
                        this.setState({
                            nextstamm: nextStamm,
                            tempnextstamm: nextStamm,
                            shownextstamm: false,
                            showroulette: false,
                            rouletteitems: [],
                            nextorganisator: nextOrga,
                        })

                    })
                    .catch(err => console.log(err))
            }
        }
    }

    NextStammShow = () => {
        this.setState({ shownextstamm: true });
    }

    NextStammChange = (event) => {
        const datum = event;
        this.setState({ tempnextstamm: datum });
    }
    NextStammSpeichern = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            nextstamm: this.state.tempnextstamm,
            shownextstamm: false
        });
        this.datenSpeichern();
    }
    NextStammAbbruch = () => {
        this.setState({
            tempnextstamm: this.state.nextstamm,
            shownextstamm: false
        });
    }

    handleOnComplete = (value) => {
        this.setState({ showroulette: false, nextorganisator: value });
        this.datenSpeichern(value);
    };

    datenSpeichern = (nextorganisator) => {
        let nextOrga = -1;
        nextOrga = this.state.allUsers.findIndex(p => {
            return p.u_name === nextorganisator;
        });
        const nr = this.state.allUsers[nextOrga].u_id;
        const zusatz = {
            d_id: this.state.stammtischID,
            d_nextdatum: this.state.nextstamm,
            d_organisator: nr
        }
        console.log(zusatz)
        axios.post('/dokuzusatz', zusatz)
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error);
            });
    }

    ShowRoulette = () => {
        this.setState({ rLoading: true });
        let userRoulette = [];
        axios.get('/lastorganizer/' + (this.props.dokuNR - 1))
            .then(response => {

                let excluder = 0;
                if (response.data.length !== 0) {
                    excluder = response.data[0].d_organisator;
                }
                for (let user in this.state.allUsers) {
                    if (this.state.allUsers[user].u_id !== excluder) {
                        userRoulette.push(this.state.allUsers[user].u_name)
                    }
                }
                this.setState({ rLoading: false });
                if (userRoulette.length === 1) {
                    this.setState({ showroulette: false, nextorganisator: userRoulette[0] });
                } else {
                    this.setState({ rouletteitems: userRoulette, showroulette: true });
                }
            })
    };


    render() {
        let organisator = '';
        if (this.state.nextorganisator) {
            organisator = <h5>{this.state.nextorganisator}</h5>
        }
        let roulette = '';
        if (this.props.userRolle < 3) {
            if (this.state.allUsers) {
                if (this.state.rLoading) {
                    roulette = <Spinner />;
                } else {
                    if (this.state.showroulette) {
                        roulette = (
                            <Roulette
                                options={this.state.rouletteitems}
                                baseSize={150}
                                onComplete={(value) => this.handleOnComplete(value)}
                            ></Roulette>
                        );
                    } else {
                        // if (this.state.new) {
                        roulette = (
                            < div className={classes.wrapdiv} > <Button btnType="contained" clicked={this.ShowRoulette}>Organisationsroulette</Button></div >
                        );
                        // }
                    }
                }
            }
        }

        const next = <span><strong>Nächster Termin:</strong></span>;
        let nextTermin;
        if (this.state.shownextstamm) {
            nextTermin = (
                <form onSubmit={this.NextStammSpeichern}>
                    <div className={classes.alodiv}>
                        <div className={classes.dyncontainer}>
                            <DatumPicker
                                Wann={this.state.tempnextstamm}
                                Beschriftung={'Datum'}
                                changed={this.NextStammChange} >
                            </DatumPicker>
                        </div>

                        <div className={classes.wrapdiv}>
                            <Button btnColor="secondary" btnType="text" clicked={this.NextStammAbbruch}>Abbrechen</Button>
                            <Button btnColor="primary" btnType="text" clicked={this.NextStammSpeichern}>Speichern</Button>
                        </div>
                        <button style={{ display: 'none' }} className="btn btn-link"></button>
                    </div>
                </form >
            )
        } else {

            if (this.props.userRolle < 3) {
                nextTermin = <h5 onClick={this.NextStammShow} style={{ cursor: 'pointer' }}><Moment format="DD.MM.YYYY">{this.state.nextstamm}</Moment></h5>
            } else {
                nextTermin = <h5><Moment format="DD.MM.YYYY">{this.state.nextstamm}</Moment></h5>
            }
        }
        return (
            <div className={classes.wrapdiv}>
                {next}
                {nextTermin}
                <span><strong>Organisator:</strong></span>
                {organisator}
                {roulette}
            </div>
        )

    }
}

export default NextTermin;