import React from 'react';

import classes from './NavigationItems.module.css'
import NavigationItem from './NavigationItem/NavigationItem';

const navigationItems = (props) => {

    let loggedInItems;
    if (props.loggedIn) {
        loggedInItems = (
            <React.Fragment>
                <NavigationItem closed={props.closed} link="/dokumentationen">
                    Dokumentationen
                </NavigationItem>
                {/* <NavigationItem link="/">
                    Abstimmungen
                </NavigationItem>
                <NavigationItem link="/">
                    Ausflug
                </NavigationItem>*/}
                <NavigationItem closed={props.closed} link="/logger">
                    Logger
                </NavigationItem>
                <NavigationItem closed={props.closed} link="/einstellungen">
                    Einstellungen
                </NavigationItem>
                <NavigationItem closed={props.closed} link="/logout">
                    {props.aktUser} ausloggen
                </NavigationItem>
            </React.Fragment>
        );
    } else {
        loggedInItems = (
            <React.Fragment>
                <NavigationItem closed={props.closed} link="/login">
                    Login
                </NavigationItem>
                <NavigationItem closed={props.closed} link="/register">
                    Register
                </NavigationItem>
            </React.Fragment>
        );
    }

    return (
        <ul className={classes.NavigationItems}>
            {loggedInItems}
        </ul>
    )
}



export default navigationItems;