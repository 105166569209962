import React from 'react';
import PropTypes from 'prop-types';

import classes from './Roulette.module.css';
import Button from './../../../UI/Button/Button';

class Roulette extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spinAngleStart: 0,
      startAngle: 0,
      spinTime: 0,
      arc: Math.PI / (props.options.length / 2),
      drehdsich: false,
      mineins: true,
      ergebnis: '',
    }
    this.spinTimer = null;
    this.handleOnClick = this.handleOnClick.bind(this);
    this.spin = this.spin.bind(this);
    this.rotate = this.rotate.bind(this);
    this.langsamStoppen = this.langsamStoppen.bind(this);
    this.stoppDasRad = this.stoppDasRad.bind(this);

  }

  static propTypes = {
    className: PropTypes.string,
    options: PropTypes.array,
    baseSize: PropTypes.number,
    spinAngleStart: PropTypes.number,
    spinTimeTotal: PropTypes.number,
    onComplete: PropTypes.func,
  };

  static defaultProps = {
    options: ['item1', 'item2', 'item3', 'item4', 'item5'],
    baseSize: 275,
    spinAngleStart: Math.random() * 30,
    spinTimeTotal: Math.random() * 3 + 4 * 1000,
    mineins: true
  };

  componentDidMount() {
    this.drawRouletteWheel();
  }

  byte2Hex(n) {
    const nybHexString = '0123456789ABCDEF';
    return String(nybHexString.substr((n >> 4) & 0x0F, 1)) + nybHexString.substr(n & 0x0F, 1);
  }

  RGB2Color(r, g, b) {
    return '#' + this.byte2Hex(r) + this.byte2Hex(g) + this.byte2Hex(b);
  }

  getColor(item, maxitem) {
    const phase = 0;
    const center = 128;
    const width = 128;
    const frequency = Math.PI * 2 / maxitem;

    const red = Math.sin(frequency * item + 2 + phase) * width + center;
    const green = Math.sin(frequency * item + 0 + phase) * width + center;
    const blue = Math.sin(frequency * item + 4 + phase) * width + center;

    return this.RGB2Color(red, green, blue);
  }

  drawRouletteWheel() {
    const { options, baseSize } = this.props;
    let { startAngle, arc } = this.state;


    // const spinTimeout = null;
    // const spinTime = 0;
    // const spinTimeTotal = 0;

    let ctx;

    const canvas = this.refs.canvas;
    if (canvas.getContext) {
      const outsideRadius = baseSize - 25;
      const textRadius = baseSize - 45;
      const insideRadius = baseSize - 55;

      ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, 600, 600);

      ctx.strokeStyle = 'white';
      ctx.lineWidth = 2;

      ctx.font = '14px Helvetica, Arial';

      for (let i = 0; i < options.length; i++) {
        const angle = startAngle + i * arc;

        ctx.fillStyle = this.getColor(i, options.length);

        ctx.beginPath();
        ctx.arc(baseSize, baseSize, outsideRadius, angle, angle + arc, false);
        ctx.arc(baseSize, baseSize, insideRadius, angle + arc, angle, true);
        ctx.fill();

        ctx.save();
        ctx.fillStyle = 'white';
        ctx.translate(baseSize + Math.cos(angle + arc / 2) * textRadius,
          baseSize + Math.sin(angle + arc / 2) * textRadius);
        ctx.rotate(angle + arc / 2 + Math.PI / 2);
        const text = options[i];
        ctx.fillText(text, -ctx.measureText(text).width / 2, 0);
        ctx.restore();
      }

      //Arrow
      ctx.fillStyle = 'red';
      ctx.beginPath();
      ctx.lineTo(baseSize + 10, baseSize - (outsideRadius + 20));
      ctx.lineTo(baseSize + 0, baseSize - (outsideRadius - 5));
      ctx.lineTo(baseSize - 10, baseSize - (outsideRadius + 20));
      ctx.fill();
      ctx.stroke();
    }
  }

  spin() {
    //const spinner = Math.floor(Math.random() * 100) + 1 ;
    this.spinTimer = null;
    this.setState({ spinTime: 0, spinAngleStart: Math.random() * 30 + 10, drehdsich: true }, () => this.rotate());
  }

  rotate() {
    const { spinTimeTotal } = this.props;
    const spinAngle = this.state.spinAngleStart - this.easeOut(0, 0, this.state.spinAngleStart, spinTimeTotal);
    this.setState({
      startAngle: this.state.startAngle + spinAngle * Math.PI / 180,
      //spinTime: this.state.spinTime + 30,
    }, () => {
      this.drawRouletteWheel();
      clearTimeout(this.spinTimer);
      this.spinTimer = setTimeout(() => this.rotate(), 30);
    })
  }

  langsamStoppen() {
    const { spinTimeTotal } = this.props;
    if (this.state.spinTime > 2800) {
      clearTimeout(this.spinTimer);
      this.stopRotateWheel();
    } else {
      const spinAngle = this.state.spinAngleStart - this.easeOut(this.state.spinTime, 0, this.state.spinAngleStart, spinTimeTotal);
      this.setState({
        startAngle: this.state.startAngle + spinAngle * Math.PI / 180,
        spinTime: this.state.spinTime + 30,
      }, () => {
        this.drawRouletteWheel();
        clearTimeout(this.spinTimer);
        this.spinTimer = setTimeout(() => this.langsamStoppen(), 30);
      })
    }
  }

  stopRotateWheel() {
    let { startAngle, arc } = this.state;
    const options = this.props.options;
    const baseSize = this.props.baseSize;

    const canvas = this.refs.canvas;
    const ctx = canvas.getContext('2d');

    const degrees = startAngle * 180 / Math.PI + 90;
    const arcd = arc * 180 / Math.PI;
    const index = Math.floor((360 - degrees % 360) / arcd);
    ctx.save();
    ctx.font = 'bold 20px Helvetica, Arial';
    const text = options[index];
    let ergebnis;
    if (this.props.options.length === 2) {
      ergebnis = options[index] + ' hat Glück'
    } else {
      ergebnis = options[index]
    }
    ctx.fillText(ergebnis, baseSize - ctx.measureText(ergebnis).width / 2, baseSize / 1);
    ctx.restore();
    this.props.options.splice(this.props.options.indexOf(text), 1);

    let mineins = true;
    if (this.props.options.length === 1) { mineins = false; }

    this.setState({
      drehdsich: false, mineins: mineins, ergebnis: text,
      arc: Math.PI / ((this.props.options.length) / 2),
    });



    //this.drawRouletteWheel();
    //this.props.onComplete(text);
  }

  easeOut(t, b, c, d) {
    const ts = (t /= d) * t;
    const tc = ts * t;
    return b + c * (tc + -3 * ts + 3 * t);
  }

  handleOnClick() {
    this.spin();
  }

  stoppDasRad() {
    this.langsamStoppen();
  }

  render() {
    const baseSize = this.props.baseSize;
    let derbutton = '';
    if (this.props.options.length > 1) {
      derbutton = <Button btnType="contained" clicked={this.handleOnClick}>Dreh das Ding</Button>
    }
    if (this.state.drehdsich) {
      derbutton = <Button btnType="contained" clicked={this.stoppDasRad}>Stoppen</Button>
    }
    let ubernehmen = '';
    if (!this.state.mineins) {
      ubernehmen = (
        <div className={classes.roulettecontainer}>
          <Button btnColor="primary" btnType="text" clicked={(value) => this.props.onComplete(this.props.options[0])}>{this.props.options[0]} organisiert den nächsten Stammtisch</Button>
        </div>
      )
    }

    return (
      <div className={classes.roulette}>
        <div className={classes.roulettecontainer}>
          <canvas ref="canvas" width={baseSize * 2} height={baseSize * 2} className="roulette-canvas"></canvas>
        </div>
        <div className={classes.roulettecontainer}>
          {derbutton}
        </div>
        {ubernehmen}
      </div>

    );
  }
}

export default Roulette;
