import React from 'react';

import Logo from '../../Logo/Logo';
import NavigationItems from '../NavigationItems/NavigationItems';
import Backdrop from '../../UI/Backdrop/Backdrop';
import classes from './SideDrawer.module.css'

const sideDrawer = (props) => {
    let attachedClasses = [classes.SideDrawer, classes.Close];
    if (props.open) {
        attachedClasses = [classes.SideDrawer, classes.Open];
    }
    return (
        <React.Fragment>
            <Backdrop show={props.open} clicked={props.closed} />
            <div className={attachedClasses.join(' ')}>
                <div className={classes.Logo}>
                    <Logo height="100%" />
                </div>
                <nav>
                    <NavigationItems
                        closed={props.closed}
                        loggedIn={props.loggedIn}
                        logout={props.logout}
                        aktUser={props.aktUser} 
                        />
                </nav>
            </div>
        </React.Fragment>
    );
};

export default sideDrawer; 