import React, { useState } from 'react';
import Button from '../../UI/Button/Button';
import StammtischNummer from './StammtischNummer/StammtischNummer';
import Anwesend from './Anwesend/Anwesend';
import Antrag from './Antrag/Antrag';
import NextTermin from './NextTermin/NextTermin';
import BilderGallerie from './BilderGallerie/BilderGallerie';
import classes from './DokuNew.module.css';
import Dialog from '../../UI/Dialog/Dialog';
import axios from '../../../axios';
import Spinner from '../../UI/Spinner/Spinner';

const DokuNew = (props) => {
    const [showDialog, setShowDialog] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);

    const DokuLoeschen = (id) => {
        setShowDialog(false);
        setShowSpinner(false);

        console.log(id)
        axios.delete('/doku/'+id)
        .then(response => {
            console.log(response)
            setShowSpinner(false);
            props.dokuAusblenden(id);
        });
    }

    let doku = <Spinner />;
    if (!showSpinner) {
        doku = (
            <React.Fragment>
                <div className={classes.fixedButton}>
                    {props.userRolle < 2 ? <Button btnColor="secondary" btnType="text" clicked={() => setShowDialog(true)}>Löschen</Button> : null}
                    <Button btnColor="secondary" btnType="text" clicked={(id) => props.dokuAusblenden(props.dokuID)}>Schließen</Button>
                </div>
                <StammtischNummer
                    dokuID={props.dokuID}
                    userRolle={props.userRolle}
                    userID={props.userID}>
                </StammtischNummer>
                <Anwesend
                    dokuID={props.dokuID}
                    userRolle={props.userRolle}>
                </Anwesend>
                <Antrag
                    dokuID={props.dokuID}
                    userRolle={props.userRolle}>
                </Antrag>
                <NextTermin
                    dokuID={props.dokuID}
                    dokuNR={props.dokuNR}
                    userRolle={props.userRolle}>
                </NextTermin>
                <BilderGallerie
                    dokuID={props.dokuID}
                    dokuNR={props.dokuNR}
                    userRolle={props.userRolle}
                    userID={props.userID}>
                </BilderGallerie>
                <Dialog
                    frage='Soll die Dokumentation wirklich gelöscht werden?'
                    buttonContinue='Ja'
                    buttonExit='Nein'
                    open={showDialog}
                    continue={(id) => DokuLoeschen(props.dokuID)}
                    exit={() => setShowDialog(false)}
                />
            </React.Fragment>
        );
    }

    return doku

}

export default DokuNew;