export const LOAD_DOKUID = 'LOAD_DOKUID';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGIN_USER_START = 'LOGIN_USER_START';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAIL = 'LOGIN_USER_FAIL';
export const LOGIN_USER_FROM_STORAGE = 'LOGIN_USER_FROM_STORAGE';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const BACK_TO_PW_CHANGE = 'BACK_TO_PW_CHANGE';
export const RESET_LOGIN_ERROR = 'RESET_LOGIN_ERROR';

export const UPDATE_SERVICEWORKER = 'UPDATE_SERVICEWORKER'