import * as actionTypes from './actionTypes';
import axios from '../../axios';

export const loginUserSuccess = (user, anmeldungSpeichern) => {
    return {
        type: actionTypes.LOGIN_USER_SUCCESS,
        user: user,
        anmeldungSpeichern: anmeldungSpeichern,
        error: ''
    };
};

export const loginUserFromStorage = (user) => {
    return dispatch => {
        dispatch(loginUserStart());
        axios.post('/userlogin', {u_id: user.u_id, username: user.u_name, password: user.u_token})
            .then(response => {
                dispatch(loginUserSuccess(response.data, true));
            })
            .catch(error => {
                dispatch(loginUserFail(error));
            });
    };    
    
    
   // return {
   //     type: actionTypes.LOGIN_USER_FROM_STORAGE,
   //     user: user
   // };
};

export const loginUserFail = (error) => {
    let answer;
    if (error.response) { 
        answer = error.response.data 
    } else {
        answer = 'SERVER_FAIL';
    }
    return {
        type: actionTypes.LOGIN_USER_FAIL,
        error: answer
    };
}

export const loginUserStart = () => {
    return {
        type: actionTypes.LOGIN_USER_START
    };
};

export const loginUser = (loginData) => {
    return dispatch => {
        dispatch(loginUserStart());
        axios.post('/userlogin', loginData)
            .then(response => {
                dispatch(loginUserSuccess(response.data, loginData.anmeldungSpeichern));
            })
            .catch(error => {
                dispatch(loginUserFail(error));
            });
    };
};

export const logoutUser = () => {
    return {
        type: actionTypes.LOGOUT_USER
    };
};

export const resetLoginError = () => {
    return {
        type: actionTypes.RESET_LOGIN_ERROR
    };
};

export const updateUser = (updatedUser) => {
    return dispatch => {
        axios.patch('/user', updatedUser)
            .then(response => {
                dispatch(updateUserSuccess(response.data));
            })
            .catch(error => {
                dispatch(updateUserFail(error));
            });
    };
};

export const updateUserSuccess = (user) => {
    return {
        type: actionTypes.UPDATE_USER_SUCCESS,
        error: ''
    };
};

export const updateUserFail = (error) => {
    return {
        type: actionTypes.UPDATE_USER_FAIL,
        error: error
    };
}

export const backToPWChange = () => {
    return {
        type: actionTypes.BACK_TO_PW_CHANGE
    };
};