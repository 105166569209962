import axios from "../axios";

const upload = (file, onUploadProgress) => {
  let formData = new FormData();
  const birthdate = new Date(file.file.lastModified);
  formData.append("birthdate", birthdate);
  formData.append("file", file.file, file.file.name);
  return axios.post('/upload/'+file.u_id, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
};

const getFiles = () => {
  return axios.get("/files");
};

export default {
  upload,
  getFiles,
};