import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    u_id : 0,
    u_name : '',
    u_token : '',
    u_rolle : 0,
    logginIn: false,
    loggedIn: false,
    error: '',
    userUpdated: false,
};

const loginUserStart = ( state, action ) => {
    return updateObject( state, { logginIn: true } );
};

const loginUserSuccess = ( state, action ) => {
    if (action.anmeldungSpeichern) {
        localStorage.setItem('u_id', action.user.u_id);
        localStorage.setItem('u_name', action.user.u_name);
        localStorage.setItem('u_token', action.user.u_passwort);
        localStorage.setItem('u_rolle', action.user.u_rolle);
    }
    return updateObject( state, {
        logginIn: false,
        loggedIn: true,
        u_id : action.user.u_id,
        u_name : action.user.u_name,
        u_token : action.user.u_passwort,
        u_rolle : action.user.u_rolle,
        error :''
    } );
};

const logoutUser = ( state, action ) => {
    localStorage.removeItem('u_id' );
    localStorage.removeItem('u_name');
    localStorage.removeItem('u_token');
    localStorage.removeItem('u_rolle');
    return updateObject( state, {
        u_id : 0,
        u_name : '',
        u_token : '',
        u_rolle : 0,        
        logginIn: false,
        loggedIn: false,
        error :''
    } );
    
};

const loginUserFail = (state, error ) => {
    return updateObject( state, { 
        logginIn: false,
        error :error.error
    } );
};

const updateUserFail = (state, error ) => {   
    console.log(error)
    return updateObject( state, { 
        error :error.error
    } );
};

const updateUserSuccess = ( state, action ) => {
    return updateObject( state, {
        error :'',
        userUpdated: true
    } );
};

const loginUserFromStorage = ( state, action ) => {
    return updateObject( state, {
        logginIn: false,
        loggedIn: true,
        u_id : action.user.u_id,
        u_name : action.user.u_name,
        u_token : action.user.u_token,
        u_rolle : action.user.u_rolle,        
        error :''
    } );
};
const backToPWChange = ( state, action ) => {
    return updateObject( state, {
        userUpdated: false
    } );
};
const resetLoginError = ( state, action ) => {
    return updateObject( state, {
        error: ''
    } );
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_USER_START: return loginUserStart( state, action );
        case actionTypes.LOGIN_USER_SUCCESS: return loginUserSuccess( state, action )
        case actionTypes.LOGIN_USER_FAIL: return loginUserFail( state, action );
        case actionTypes.LOGOUT_USER: return logoutUser( state, action );
        case actionTypes.LOGIN_USER_FROM_STORAGE: return loginUserFromStorage( state, action );
        case actionTypes.UPDATE_USER_SUCCESS: return updateUserSuccess( state, action )
        case actionTypes.UPDATE_USER_FAIL: return updateUserFail( state, action );      
        case actionTypes.BACK_TO_PW_CHANGE: return backToPWChange( state, action );   
        case actionTypes.RESET_LOGIN_ERROR: return resetLoginError( state, action );   
        default: return state;
    }
};

export default reducer;