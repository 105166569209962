import React, { Component } from 'react';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import FileUpload from '../../../FileUpload/FileUpload';
import AxiosImage from '../../../UI/AxiosImage/AxiosImage';
import axios from '../../../../axios';
import classes from './BilderGallerie.module.css';
import Modal from '../../../UI/Modal/ModalUI';
import GallerySlider from './BilderView';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '../../../UI/Dialog/Dialog';

class BilderGallerie extends Component {

    state = {
        stammtischID: 0,
        stammtischnr: 0,
        allUsers: null,
        docuPics: null,
        myGallery: [],
        showModal: false,
        galleryIndex: 0,
        screenWidth: 0,
        showDialog: false,
        fileToDelete: 0,
    }

    componentDidMount() {
        this.setState({ screenWidth: window.innerWidth });
        window.addEventListener('resize', this.handleResize)
        if (!this.state.allUsers) {
            axios.get('/activeuser/')
                .then(response => {
                    this.setState({ allUsers: response.data });
                });
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }
    handleResize = () => {
        this.setState({ screenWidth: window.innerWidth });
    }

    componentDidUpdate() {
        if (this.props.dokuID) {
            if (this.state.stammtischID !== this.props.dokuID) {
                axios.get('/getdokudocs/' + this.props.dokuID)
                    .then(response => {
                        this.setState({
                            stammtischID: this.props.dokuID,
                            stammtischnr: this.props.dokuNR,
                            docuPics: response.data
                        })
                    })
            }
        }
    }
    addToGallery = (img) => {
        let kopieMyGallery = [...this.state.myGallery];
        kopieMyGallery.push(img);
        kopieMyGallery.sort((a, b) => a.id - b.id);
        this.setState({ myGallery: kopieMyGallery });
    }

    openModal = (value,prevState) => {
        this.setState({ showModal: true, galleryIndex: value })
    }

    closeModal = () => {
        this.setState({ showModal: false })
    }

    deleteImage = (e, id) => {
        e.stopPropagation();
        console.log(id)
        this.setState({  showDialog: true, fileToDelete: id })
    }
    
    dialogExit = () => {
        this.setState({showDialog: false, fileToDelete: 0 })
    }
    bildLoeschen = () => {
        console.log(this.state.fileToDelete)
        axios.delete('/file/'+this.state.fileToDelete)
        .then(response => {
            console.log(response)
            
            const bildIndex = this.state.docuPics.findIndex(p => {
                return p.id === this.state.fileToDelete;
            });
            let kopiePics = [...this.state.docuPics];
            kopiePics.splice(bildIndex, 1);
            this.setState({
                docuPics: kopiePics,
                fileToDelete: 0,
            });

        })
        .catch(error => console.log(error))
        this.setState({showDialog: false })
    }

    handleOnUploadComplete = (value) => {
        axios.post('/docdoku', { doc_id: value, doku_id: this.state.stammtischID })
            .then(response => {
                let kopieMyGallery = [...this.state.docuPics];
                kopieMyGallery.push({ id: value, u_id: this.props.userID, doc_added: new Date() });
                this.setState({ docuPics: kopieMyGallery });
            })
            .catch(error => {
                console.log(error);
            });
    };

    render() {
        let myGallery = '';
        if (this.state.docuPics) {
            myGallery = (

                <GridList spacing={4} cols={this.state.screenWidth < 650 ? 1 : 2} cellHeight={150} >
                    {this.state.docuPics.map((tile, index) => {
                        let uploadedWann = '';
                        let uploadedVon = '';
                        if (this.state.allUsers) {
                            let userIndex = -1;
                            userIndex = this.state.allUsers.findIndex(p => {
                                return p.u_id === tile.u_id;
                            });

                            if (userIndex > -1) {
                                uploadedVon = 'Upload: ' + this.state.allUsers[userIndex].u_name;
                            }
                            if (tile.create_date) {
                                uploadedWann = new Date(tile.create_date).toLocaleDateString("de-DE") + ' - ' + new Date(tile.create_date).toLocaleTimeString("de-DE");
                            }
                        }
                        let myActionButton = null;
                        if (tile.u_id === this.props.userID || this.props.userRolle ===1) {
                            myActionButton = (
                                <IconButton onClick={(event) => this.deleteImage(event, tile.id)} aria-label={`delete`} style={{ color: 'rgba(255, 255, 255, 0.70)' }}>
                                    <DeleteIcon />
                                </IconButton>
                            );
                        }

                        return (
                            <GridListTile style={{ cursor: 'pointer' }} onClick={() => this.openModal(index)} key={tile.id}>
                                <AxiosImage sendBack={(value) => this.addToGallery(value)} alt={'Stammtisch'} docID={tile.id}></AxiosImage>
                                <GridListTileBar
                                    title={uploadedVon}
                                    subtitle={uploadedWann}
                                    actionIcon={myActionButton} />
                            </GridListTile>
                        )
                    })}
                </GridList>
            );
        }
        return (
            <div className={classes.wrapdiv}>
                <span><strong>Bildergallerie:</strong></span>

                <Modal
                    open={this.state.showModal}
                    close={this.closeModal}
                    data={<GallerySlider galleryData={this.state.myGallery}
                        index={this.state.galleryIndex} />}
                />
                {myGallery}
                <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.54)', textAlign: 'center', position: 'sticky', bottom: '-16px', zIndex: '999' }}>
                    <FileUpload theme='dunkel' icon='plus' userID={this.props.userID} onComplete={(value) => this.handleOnUploadComplete(value)} />
                </div>
                <Dialog
                    frage='Soll das Bild wirklich gelöscht werden?'
                    buttonContinue='Ja'
                    buttonExit='Nein'
                    open={this.state.showDialog}
                    continue={this.bildLoeschen}
                    exit={this.dialogExit}
                />                
            </div>
        );
    }
};

export default BilderGallerie;