import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../store/actions/index';
import classes from './Layout.module.css';
import Toolbar from '../Navigation/Toolbar/Toolbar';
import SideDrawer from '../Navigation/SideDrawer/SideDrawer';

class Layout extends Component {
    state = {
        showSideDrawer: false
    }

    sideDrawerClosedHandler = () => {
        this.setState({showSideDrawer:false});
    }

    sideDrawerToggleHandler = () => {
        this.setState((prevState)=> {
            return { showSideDrawer:!prevState.showSideDrawer};
        } );
    }

    render () {
        return ( 
            <React.Fragment>
                <Toolbar 
                    drawerToggleClicked={this.sideDrawerToggleHandler}
                    loggedIn={this.props.loggedIn}
                    logout={this.props.logout}
                    aktUser={this.props.aktUser}
                />
                <SideDrawer closed={this.sideDrawerClosedHandler}
                    open={this.state.showSideDrawer}
                    loggedIn={this.props.loggedIn}
                    logout={this.props.logout}
                    aktUser={this.props.aktUser}/>
                <main className={classes.Content}>
                    {this.props.children}
                </main>
            </React.Fragment>
        )
    }

}

const mapStateToProps = state => {
    return {
        aktUser: state.users.u_name,
        loggedIn: state.users.loggedIn
    }
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(actions.logoutUser())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);